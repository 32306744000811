import React, { useEffect, useState } from "react";
import { FaEye } from "react-icons/fa";
import { ThreeDots } from "react-loader-spinner";
import PaperWiseTable from "./PaperWiseTable";
import DropdownOptions from "./DropDown";
import TableComponent from "./TableComponent";
import AdminDetails from "./AdminDetails";
import AdminMenu from "../AdminMenu";

const base64 = require("base-64");

const Dashboard = () => {
  const [selectedValue, setSelectedValue] = useState("");
  const [apiData, setApiData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [itsId, setItsId] = useState([]);
  const [urlLast, setUrlLast] = useState("");
  const [report, setReport] = useState("");

  const baseUrl = `${process.env.REACT_APP_API_URL}admin/`;
  const pairs = document.cookie.split(";");
  let cookies = {};
  for (var i = 0; i < pairs.length; i++) {
    var pair = pairs[i].split("=");
    cookies[(pair[0] + "").trim()] = unescape(pair.slice(1).join("="));
  }
  const authToken = base64.encode(cookies.userid + ":" + cookies.ver);

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      if (!selectedValue) return;

      setIsLoading(true);
      setApiData(null);
      setError(null);

      try {
        const apiUrl = `${baseUrl}user_list/all/${selectedValue}/all`;
        const parts = apiUrl.split("/");
        const lastPart = parts[parts.length - 2];

        setUrlLast(lastPart);

        const myHeaders = new Headers();
        myHeaders.append("Authorization", "Basic " + authToken);

        const requestOptions = {
          method: "GET",
          headers: myHeaders,
        };

        const response = await fetch(apiUrl, requestOptions);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        if (isMounted) {
          if (!data || !data.data || data.data.length === 0) {
            setError("No data found");
          } else {
            setApiData(data);
          }
        }
      } catch (error) {
        if (isMounted) {
          setError("Failed to fetch data. Please try again later.");
          console.error("Error fetching data:", error);
        }
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [selectedValue, authToken, baseUrl]);

  const handleEyeIconClick = (row) => {
    if (row?.original?.its_id) {
      setItsId(row.original.its_id);
      const reportUrl = `/admin/marksheet/${urlLast}/${row.original.its_id}`;
      window.open(reportUrl, "_blank");
      setReport(reportUrl);
    }
  };

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const columns = [
    { Header: "No.", accessor: (row, index) => index + 1, id: "indexColumnId" },
    { Header: "its ID", accessor: "its_id", id: "itsColoumnId" },
    { Header: "Username", accessor: "name", id: "nameColumnId" },
    { Header: "Jammat", accessor: "jamaat", id: "jammatColumnId" },
    { Header: "Jamiat", accessor: "jamiat", id: "jamiatColumnId" },
    { Header: "Country", accessor: "country", id: "countryColumnId" },
    { Header: "City", accessor: "city", id: "cityColumnId" },
    { Header: "P.no.", accessor: "phone", id: "phoneColumnId" },
    {
      Header: "Action",
      accessor: "action",
      Cell: ({ row }) => (
        <FaEye
          style={{ cursor: "pointer" }}
          size={18}
          color="blue"
          alt="eyelogo"
          className="icon"
          onClick={() => handleEyeIconClick(row)}
        />
      ),
    },
  ];

  const columns2 = [
    {
      Header: "Quizz Name",
      accessor: "quizName",
      id: "nameQuizzNameId",
    },
    { Header: "Total Users", accessor: "totalusers", id: "nameTotalUsersId" },
    {
      Header: "Passed Users",
      accessor: "totalPassedUser",
      id: "namePassedUsersId",
    },
    {
      Header: "Failed Users",
      accessor: "totalFailedUser",
      id: "nameFailedUsersId",
    },
    {
      Header: "100% Score",
      accessor: "total100PercScoreCount",
      id: "nameScoreId",
    },
    { Header: "Avg Time", accessor: "avgTime", id: "nameAvgTimeId" },
    {
      Header: "Actions",
      accessor: "",
      id: "nameActionsId",
    },
  ];

  const renderTableContent = () => {
    if (isLoading) {
      return (
        <div
          style={{ display: "flex", justifyContent: "center", padding: "2rem" }}
        >
          <ThreeDots
            height="50"
            width="50"
            radius="9"
            color="#4fa94d"
            ariaLabel="three-dots-loading"
          />
        </div>
      );
    }

    if (error) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "2rem",
            color: "#666",
            fontSize: "1.1rem",
            textAlign: "center",
          }}
        >
          {error}
        </div>
      );
    }

    if (!apiData?.data) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "2rem",
            color: "#666",
            fontSize: "1.1rem",
            textAlign: "center",
          }}
        >
          Please select a value from the dropdown to view data.
        </div>
      );
    }

    return (
      <TableComponent
        columns={columns}
        data={apiData.data.map((row) => ({ ...row, id: row.its_id }))}
      />
    );
  };

  return (
    <>
      <div className="container-admin" style={{ marginTop: "10px" }}>
        <AdminMenu />
        <div className="container-table" style={{ marginLeft: "10px" }}>
          <DropdownOptions
            selectedValue={selectedValue}
            handleSelectChange={handleSelectChange}
          />
          <AdminDetails baseUrl={baseUrl} lastUrl={urlLast} />
          <PaperWiseTable
            baseUrl={baseUrl}
            lastUrl={urlLast}
            columns2={columns2}
          />

          <div className="admin-table">
            <h4
              style={{
                fontSize: "1em",
                margin: "15px",
                fontWeight: "bold",
                color: "rgba(18,21,78,.7)",
              }}
            >
              USER LIST
            </h4>
            <hr className="underline" />
            {renderTableContent()}
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
