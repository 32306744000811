import React, { useEffect, useState } from "react";
import { PieChart } from "@mui/x-charts/PieChart";
import { ThreeDots } from "react-loader-spinner";
import { useParams } from "react-router-dom";
import AdminDetails from "../AdminDetails";

export const TotalUserDetails = ({ groupId }) => {
  const { quizId } = useParams();

  const baseUrl = "https://talabulilm.com/imtihaan/imtihaan-api/api/get/";
  const [url4Data, setUrl4Data] = useState([]);
  const [url5Data, setUrl5Data] = useState("");

  const chartData = [
    ["Task", "Hours per total"],
    [
      "ARB",
      url5Data[3]?.getTotalUniqueUsers
        ? parseInt(url5Data[3].getTotalUniqueUsers)
        : 0,
    ],
    [
      "ENG",
      url5Data[2]?.getTotalUniqueUsers
        ? parseInt(url5Data[2].getTotalUniqueUsers)
        : 0,
    ],
    [
      "GUJ",
      url5Data[1]?.getTotalUniqueUsers
        ? parseInt(url5Data[1].getTotalUniqueUsers)
        : 0,
    ],
    [
      "LSD",
      url5Data[0]?.getTotalUniqueUsers
        ? parseInt(url5Data[0].getTotalUniqueUsers)
        : 0,
    ],
  ];
  useEffect(() => {
    const fetchUrlData = async () => {
      if (!quizId) {
        return;
      }
      try {
        const url4 = `${baseUrl}total_completed_unique_user/${quizId}/${groupId}/all `;
        const response4 = await fetch(url4);
        const data4 = await response4.json();
        setUrl4Data(data4);

        const url5 = `${baseUrl}langauge_wise_user_count/${quizId}/${groupId}/all `;
        const response5 = await fetch(url5);
        const data5 = await response5.json();
        setUrl5Data(data5.data);
        // console.log(url5Data, "url5");
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchUrlData();
  }, []);
  // console.log(url5Data, "url5");
  return (
    <>
      <div className="admin-table ">
        <div className="row">
          <div className="col-md-8" style={{ marginTop: "10px" }}>
            <span
              className="data-container"
              style={{ fontWeight: "700", color: "#495057" }}
            >
              Total Unique Users
              <div
                className="widget-numbers text-success"
                style={{ fontSize: "1.8rem" }}
              >
                {url4Data ? `${url4Data.data}` : "Null"}
              </div>
            </span>
            <hr className="underline"></hr>

            <div className="data-container">
              <div className="col-md-5">
                {/* <span
                  className="data-container"
                  style={{ fontWeight: "700", color: "#495057" }}
                >
                  Total ARB Users
                  <div
                    className="widget-numbers text-danger"
                    style={{ fontSize: "1.8rem" }}
                  ></div>
                  <hr className="underline"></hr>
                </span>

                <span
                  className="data-container"
                  style={{ fontWeight: "700", color: "#495057" }}
                >
                  Total GUJ Users
                  <div
                    className="widget-numbers text-danger"
                    style={{ fontSize: "1.8rem" }}
                  ></div>
                  <hr className="underline"></hr>
                </span> */}
              </div>
              <div className="col-md-5">
                {/* <span
                  className="data-container"
                  style={{ fontWeight: "700", color: "#495057" }}
                >
                  Total ENG Users
                  <div
                    className="widget-numbers text-danger"
                    style={{ fontSize: "1.8rem" }}
                  ></div>
                  <hr className="underline"></hr>
                </span> */}

                {url5Data && url5Data[0] && url5Data[0].getTotalUniqueUsers ? (
                  <span
                    className="data-container"
                    style={{ fontWeight: "700", color: "#495057" }}
                  >
                    Total LSD Users
                    <div
                      className="widget-numbers text-danger"
                      style={{ fontSize: "1.8rem" }}
                    >
                      <span
                        className="widget-numbers text-danger"
                        style={{ fontSize: "1.8rem" }}
                      >
                        {url5Data[0].getTotalUniqueUsers}
                      </span>
                    </div>
                    <hr className="underline" />
                  </span>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="=row-md-4">
              <span style={{ margin: "1em" }}>
                {url5Data === 0 ? (
                  <PieChart
                    series={[
                      {
                        data: chartData.slice(1).map(([label, value]) => ({
                          id: label,
                          value,
                          label,
                        })),
                        innerRadius: 40,
                        outerRadius: 100,
                        paddingAngle: 1,
                        cornerRadius: 7,
                        startAngle: -90,
                        endAngle: 360,
                        highlightScope: {
                          faded: "global",
                          highlighted: "item",
                        },
                        faded: {
                          innerRadius: 30,
                          additionalRadius: -30,
                          color: "gray",
                        },
                      },
                    ]}
                    width={400}
                    height={200}
                  />
                ) : (
                  ""
                )}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
