import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Toolbar,
  Typography,
  Link,
  Menu,
  MenuItem,
  IconButton,
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: 120,
    [theme.breakpoints.down("sm")]: {
      height: "auto", // Changed to auto to accommodate the mobile profile
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    fontSize: 24,
    marginLeft: 25,
  },
  appBar: {
    height: 120,
    backgroundColor: "#1D3557",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      height: 80,
      width: "100vw",
    },
  },
  appBarDesktop: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  appBarMobile: {
    visibility: "hidden",
    height: 0,
    display: "flex",
    justifyContent: "space-between",
    padding: "0px 20px",
    [theme.breakpoints.down("sm")]: {
      visibility: "visible",
      height: "auto",
      width: "100vw",
    },
  },
  userImage: {
    width: 60,
    height: 80,
    borderRadius: 25,
    marginLeft: 20,
  },
  menuContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignContent: "flex-end",
  },
  menuItem: {
    color: "#F1FAEE",
    fontSize: 14,
  },
  userDetails: {
    color: "#ffffff",
    fontSize: 16,
    fontWeight: 200,
  },
  links: {
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
    letterSpacing: 0.5,
    marginTop: 8,
  },
  userContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  h6: {
    fontSize: 24,
  },
  mobileProfileContainer: {
    display: "none", // Hidden by default
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "12px 20px",
      backgroundColor: "#1D3557",
      borderTop: "1px solid rgba(255, 255, 255, 0.1)",
    },
  },
  mobileUserImage: {
    width: 40,
    height: 40,
    borderRadius: 20,
    marginRight: 12,
  },
  mobileUserName: {
    color: "#ffffff",
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 1,
    width: "max-content",
  },
}));

export default function CustomAppBar(props) {
  const { common, setUserDetails } = props;
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuItems = [
    {
      name: "Araiz",
      link: "https://www.talabulilm.com/araiz",
    },
    {
      name: "Istifaadah",
      link: "https://istifaadah.talabulilm.com",
    },
    {
      name: "Istibsaar",
      link: "https://www.talabulilm.com/istibsaar_new",
    },
    {
      name: "Workshops",
      link: "https://www.talabulilm.com/workshops",
    },
    {
      name: "Minhat Taalimiyah",
      link: "https://www.talabulilm.com/minhat",
    },
  ];

  const list = () => (
    <div role="presentation" onClick={() => setIsDrawerOpen(false)}>
      <List>
        <ListItem>
          <ListItemIcon>
            <CloseIcon
              style={{ position: "absolute", right: 15, cursor: "pointer" }}
            />
          </ListItemIcon>
        </ListItem>
      </List>
      <List>
        <ListItem
          component="a"
          href="https://www.talabulilm.com/profile/"
          button
        >
          <ListItemText primary="Profile" />
        </ListItem>
      </List>
      <Divider />
      <List>
        {menuItems.map((item, index) => (
          <ListItem component="a" href={item.link} button key={index}>
            <ListItemText primary={item.name} />
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        <ListItem
          component="a"
          href="https://www.talabulilm.com/log-out/"
          button
        >
          <ListItemText primary="Logout" />
        </ListItem>
      </List>
    </div>
  );

  return (
    <>
      <div className={classes.root}>
        <Drawer
          anchor="right"
          open={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
        >
          {list()}
        </Drawer>
        <AppBar className={classes.appBar} position="static">
          <Toolbar className={classes.appBarDesktop}>
            <Link href="/" style={{ textDecoration: "none", color: "#ffffff" }}>
              <img
                src={"https://imtihaan.talabulilm.com/maktab_majma_logo.png"}
                alt="mhb-logo"
                style={{ width: 90, maxHeight: 114 }}
              />
            </Link>
            <Typography
              variant="h6"
              className={classes.title}
              style={{
                display: "grid",
                gridTemplateColumns: "auto 1fr",
                justifyItems: "center",
                alignItems: "center",
              }}
            >
              <Link
                href="/"
                style={{
                  textDecoration: "none",
                  lineHeight: 1.3,
                  textAlign: "center",
                  color: "#ffffff",
                }}
              >
                Imtihaan <br />
                Home
              </Link>
              <img
                src={
                  "https://imtihaan.talabulilm.com/imtihaan_logo_white_1446.png"
                }
                style={{
                  width: 480,
                  paddingLeft: 20,
                  paddingRight: 20,
                  maxHeight: 110,
                }}
              />
            </Typography>
            <div className={classes.menuContainer}>
              <div className={classes.userContainer}>
                <Typography className={classes.userDetails} variant="h6">
                  {common.userDetails.name && common.userDetails.name}
                </Typography>
              </div>
              <Typography className={classes.links}>
                {menuItems.map((item, idx) => (
                  <Link
                    key={idx}
                    href={item.link}
                    target="_blank"
                    className={classes.menuItem}
                  >
                    {item.name}
                  </Link>
                ))}
              </Typography>
            </div>
            <img
              style={{ cursor: "pointer" }}
              onClick={handleClick}
              className={classes.userImage}
              src={`https://www.talabulilm.com/mumin_images/${common.userDetails.its_id}.png`}
              alt="logo"
            />
          </Toolbar>
          <div className={classes.appBarMobile}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Link
                href="/"
                style={{ textDecoration: "none", color: "#ffffff" }}
              >
                <img
                  src={"https://imtihaan.talabulilm.com/maktab_majma_logo.png"}
                  style={{ width: 50, marginRight: 6 }}
                  alt="logo"
                />
              </Link>
            </div>
            {/* <Typography variant="h6" > */}
            <Link
              href="/"
              style={{
                textDecoration: "none",
                color: "#ffffff",
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                src="https://imtihaan.talabulilm.com/imtihaan_logo_white_1446.png"
                style={{ maxHeight: 50 }}
              />
            </Link>
            {/* </Typography> */}
            <IconButton onClick={() => setIsDrawerOpen(true)}>
              <MenuIcon style={{ color: "#fff" }} size="large" />
            </IconButton>
          </div>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            style={{ marginTop: 50 }}
          >
            <Link
              style={{ textDecoration: "none" }}
              href="https://www.talabulilm.com/profile/"
            >
              <MenuItem
                style={{
                  width: 300,
                  backgroundColor: "#F1FAEE",
                  color: "#E63946",
                }}
                onClick={handleClose}
              >
                My Profile
              </MenuItem>
            </Link>
            <Link
              style={{ textDecoration: "none" }}
              href="https://www.talabulilm.com/log-out/"
            >
              <MenuItem
                style={{
                  width: 300,
                  backgroundColor: "#F1FAEE",
                  color: "#E63946",
                }}
                onClick={handleClose}
              >
                Logout
              </MenuItem>
            </Link>
          </Menu>
        </AppBar>
        <div className={classes.mobileProfileContainer}>
          <Typography className={classes.mobileUserName}>
            {common.userDetails.name}
          </Typography>
          <img
            className={classes.mobileUserImage}
            src={`https://www.talabulilm.com/mumin_images/${common.userDetails.its_id}.png`}
            alt={common.userDetails.name}
          />
        </div>
      </div>
    </>
  );
}
