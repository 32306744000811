import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getFrontQuizList,
  getLanguages,
} from "../../actions/homeAction/homeAction";
import {
  getRank,
  getLeaderboard,
  getJamiatLeaderboard,
  getJamaatLeaderboard,
} from "../../actions/marksheetAction/marksheetAction";
import Quiz from "../../components/quiz/quiz";

export class QuizCont extends Component {
  render() {
    return <Quiz {...this.props} />;
  }
}

export const mapStateToProps = (store) => {
  return {
    common: store.common,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    getFrontQuizList: (user_id, list_id) => {
      dispatch(getFrontQuizList(user_id, list_id));
    },
    getLanguages: () => {
      dispatch(getLanguages());
    },
    getRank: (user_id, id, isAdmin) => {
      dispatch(getRank(user_id, id, isAdmin));
    },
    getLeaderboard: (user_its, group_id) => {
      dispatch(getLeaderboard(user_its, group_id));
    },
    //new
    getJamiatLeaderboard: (group_id, jamiat_id) => {
      dispatch(getJamiatLeaderboard(group_id, jamiat_id));
    },
    getJamaatLeaderboard: (group_id, jamaat_id) => {
      dispatch(getJamaatLeaderboard(group_id, jamaat_id));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(QuizCont);
