import React, { useState, useEffect } from "react";
import DashBoard from "./DashBoard/DashBoard";
import { useSpring, animated } from "react-spring";

const base64 = require("base-64");

export default function Admin(props) {
  const [isAdmin, setIsAdmin] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const pairs = document.cookie.split(";");
  let cookies = {};
  for (var i = 0; i < pairs.length; i++) {
    var pair = pairs[i].split("=");
    cookies[(pair[0] + "").trim()] = unescape(pair.slice(1).join("="));
  }
  const authToken = base64.encode(cookies.userid + ":" + cookies.ver);

  const slideInFromLeft = useSpring({
    from: { opacity: 0, transform: "translateX(-100%)" },
    to: { opacity: 1, transform: "translateX(0%)" },
    config: { tension: 250, friction: 30 },
  });

  const messageStyles = {
    container: {
      width: "100%",
      height: "100vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#f5f5f5",
    },
    messageBox: {
      padding: "30px",
      backgroundColor: "white",
      borderRadius: "8px",
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
      textAlign: "center",
    },
    title: {
      fontSize: "24px",
      color: "#dc3545",
      marginBottom: "10px",
      fontWeight: "bold",
    },
    message: {
      color: "#666666",
      fontSize: "16px",
    },
  };

  useEffect(() => {
    checkAdminStatus();
  }, [authToken]);

  const checkAdminStatus = async () => {
    try {
      let myHeaders = new Headers();
      myHeaders.append("Authorization", "Basic " + authToken);

      let requestOptions = {
        method: "GET",
        headers: myHeaders,
      };

      // Replace with your actual API endpoint
      const response = await fetch(
        "https://www.talabulilm.com/api2022/imtihaan/user/check/admin",
        requestOptions
      );

      if (!response.ok) {
        throw new Error("Failed to verify admin status");
      }

      const data = await response.json();
      setIsAdmin(data.response);
    } catch (error) {
      console.error("Error checking admin status:", error);
      setIsAdmin(false);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <div style={messageStyles.container}>
        <div style={messageStyles.messageBox}>
          <div style={messageStyles.message}>Loading...</div>
        </div>
      </div>
    );
  }

  if (!isAdmin) {
    return (
      <animated.div style={slideInFromLeft}>
        <div style={messageStyles.container}>
          <div style={messageStyles.messageBox}>
            <div style={messageStyles.title}>Access Denied</div>
            <div style={messageStyles.message}>
              Sorry, you don't have permission to access this page.
            </div>
          </div>
        </div>
      </animated.div>
    );
  }

  return (
    <div style={{ width: "99%" }}>
      <div className="col">
        <div className="row">
          <animated.div style={slideInFromLeft}>
            <DashBoard />
          </animated.div>
        </div>
      </div>
    </div>
  );
}

// import React from "react";
// import DashBoard from "./DashBoard/DashBoard";
// import { useSpring, animated } from "react-spring";

// export default function Admin(props) {
//   const slideInFromLeft = useSpring({
//     from: { opacity: 0, transform: "translateX(-100%)" },
//     to: { opacity: 1, transform: "translateX(0%)" },
//     config: { tension: 250, friction: 30 },
//   });

//   return (
//     <div style={{ width: "99%" }}>
//       <div className="col">
//         <div className="row">
//           <animated.div style={slideInFromLeft}>
//             <DashBoard />
//           </animated.div>
//         </div>
//       </div>
//     </div>
//   );
// }
