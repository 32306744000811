import React, { useState, useEffect } from "react";
import axios from "axios";
import AdminMenu from "../AdminMenu";

const base64 = require("base-64");
const pairs = document.cookie.split(";");
let cookies = {};
for (var i = 0; i < pairs.length; i++) {
  var pair = pairs[i].split("=");
  cookies[(pair[0] + "").trim()] = unescape(pair.slice(1).join("="));
}
const authToken = base64.encode(cookies.userid + ":" + cookies.ver);

const MarhalaReport = () => {
  const [totalUsers, setTotalUsers] = useState([]);
  const [marhalaData, setMarhalaData] = useState([]);

  const [loading, setLoading] = useState();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}admin/marhala_wise_user_count`,
          {
            headers: {
              Authorization: "Basic " + authToken,
            },
          }
        );
        setMarhalaData(response.data);
        setTotalUsers(response.data.totals);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="container-admin" style={{ marginTop: "20px" }}>
      <AdminMenu />

      <div
        className="admin-table"
        style={{
          height: "19em",
          marginLeft: "20px",
          padding: "30px",
        }}
      >
        <span
          className="data-container"
          style={{ fontWeight: "700", color: "#495057" }}
        >
          Total Female Users
          <div
            className="widget-numbers text-success"
            style={{ fontSize: "1.8rem" }}
          >
            {totalUsers.totalFemale}
          </div>
        </span>
        <hr className="underline"></hr>
        <span
          className="data-container"
          style={{ fontWeight: "700", color: "#495057" }}
        >
          Total Male Users
          <div
            className="widget-numbers text-success"
            style={{ fontSize: "1.8rem" }}
          >
            {totalUsers.totalMale}
          </div>
        </span>
        <hr className="underline"></hr>

        <div className="col-md-5">
          <span
            className="data-container"
            style={{ fontWeight: "700", color: "#495057" }}
          >
            Total Users
            <div
              className="widget-numbers text-danger"
              style={{ fontSize: "1.8rem" }}
            >
              {totalUsers.totalUsers}
            </div>
            <hr className="underline"></hr>
          </span>
        </div>
      </div>
    </div>
  );
};

export default MarhalaReport;
