import React, { useEffect, useState } from "react";
import {
  Typography,
  Grid,
  AccordionActions,
  AccordionSummary,
  AccordionDetails,
  Card,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  LinearProgress,
  Box,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import MuiAccordion from "@material-ui/core/Accordion";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import CustomButton from "../UI/button";
import Certificate from "../../images/certificate.png";
import Scholar from "../../images/scholar.png";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Footer from "../footer";
import { IMAGES } from "../../constants/commonConst";
import { Close } from "@material-ui/icons";

import Confetti from "react-confetti";

var base64 = require("base-64");

const useStyles = makeStyles((theme) => ({
  paper: {
    height: 140,
    width: 450,
  },
  inActiveAccordion: {
    backgroundColor: "#457B9D",
    color: "#F1FAEE",
    textTransform: "uppercase",
    textDecoration: "none",
    minHeight: 60,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  inActiveAccordionDetails: {
    backgroundColor: "#F1FAEE",
    color: "#457B9D",
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
  inActiveAccordionButton: {
    borderRadius: 5,
    border: "1px solid #709395",
    textTransform: "none",
    fontWeight: "bold",
    textDecoration: "none",
    marginBottom: 10,
  },
  activeAccordion: {
    backgroundColor: "#E63946",
    color: "#F1FAEE",
    textTransform: "uppercase",
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  activeAccordionDetails: {
    backgroundColor: "#FFFFFF",
    color: "#2F3F60",
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
  button: {
    textTransform: "none",
  },
  timelineList: {
    backgroundColor: "#A8DADC",
    border: "1px solid #457B9D",
    "&:hover": {
      backgroundColor: "#A8DADC",
    },
  },
  promotionCard: {
    margin: "20px 0px 20px 0px",
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
      marginBottom: 0,
    },
  },
  pb8: {
    paddingBottom: 8,
  },
  dark: {
    color: "#1E3456",
  },
  hideOtherCourse: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  showOtherCourse: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      marginBottom: 20,
    },
  },
  progressBorder: {
    border: "1px solid rgba(0, 0, 0, 0.12)",
    padding: 15,
    borderRadios: 4,
  },
  certImage: {
    width: 30,
    backgroundColor: "#fff",
    padding: 3,
    borderRadius: 3,
  },
  paddingGrid: {
    maxWidth: "100%",
    paddingBottom: 16,
    marginTop: 16,
    marginLeft: "auto",
    marginRight: "auto",
  },
}));

const styles = {
  badgeWrapper: {
    backgroundColor: "#fff",
    padding: "10px 20px 5px",
    lineHeight: 1.3,
    letterSpacing: 0.7,
    fontSize: "14px",
  },
  badgeLink: {
    color: "#0d6efd",
    textDecoration: "underline",
    fontSize: "14px",
    fontWeight: 600,
  },
  container: {
    width: "100%",
    maxWidth: "1200px",
    margin: "0 auto",
    padding: window.innerWidth <= 480 ? "10px" : "20px",
    boxSizing: "border-box",
  },
  header: {
    backgroundColor: "#457B9D",
    padding: window.innerWidth <= 480 ? "12px" : "18px",
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
  },
  headerText: {
    color: "#F1FAEE",
    margin: 0,
    fontSize: window.innerWidth <= 480 ? "1rem" : "1.25rem",
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "repeat(4, minmax(60px,1fr))",
    gap: window.innerWidth <= 768 ? "8px" : "6px",
    padding: window.innerWidth <= 768 ? "10px" : "10px",
    backgroundColor: "#fff",
    borderBottomLeftRadius: "20px",
    borderBottomRightRadius: "20px",
  },
  badgeItem: (isAssigned) => ({
    cursor: isAssigned ? "pointer" : "not-allowed",
    width: "100%",
    aspectRatio: "1/2",
    maxWidth: window.innerWidth <= 768 ? "100%" : "200px",
    justifySelf: "center",
    opacity: isAssigned ? 1 : 0.5,
  }),
  badgeCard: {
    height: "95%",
    width: "100%",
    backgroundColor: "#F1FAEE",
    borderRadius: "10px",
    padding: window.innerWidth <= 768 ? "3px" : "5px",
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
  },
  imageContainer: {
    flex: 8,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "5px",
  },
  image: (isAssigned) => ({
    width: "100%",
    height: "100%",
    objectFit: "contain",
    filter: isAssigned ? "none" : "grayscale(100%)",
  }),
  nameContainer: {
    flex: 2,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px",
    textAlign: "center",
  },
  nameText: {
    fontSize: window.innerWidth <= 768 ? "10px" : "12px",
    fontFamily: "Arial",
    color: "#999",
  },
  noBadges: {
    gridColumn: "1 / -1",
    textAlign: "center",
    padding: "20px",
    color: "#999",
  },
};

const Accordion = withStyles({
  root: {
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

export default function Home(props) {
  const {
    common,
    getCertificates,
    getTimelines,
    getGroupListFront,
    getGroupInfo,
    getFrontPromotionList,
    getBadges,
  } = props;

  const classes = useStyles();

  const [expanded, setExpanded] = useState(false);
  // State to control the popup visibility and content
  const [open, setOpen] = useState(false);
  const [selectedBadge, setSelectedBadge] = useState(null);
  const [showConfetti, setShowConfetti] = useState(false);

  // Handle the popup open and close
  const handleOpen = (badge) => {
    setSelectedBadge(badge);
    setOpen(true);
    setShowConfetti(true); // Show confetti when the dialog opens

    // Stop confetti after 4 seconds
    setTimeout(() => {
      setShowConfetti(false);
    }, 4000);
  };
  const handleClose = () => setOpen(false);

  useEffect(() => {
    localStorage.setItem(
      "authToken",
      base64.encode(common.userDetails.its_id + ":" + common.userVer)
    );
    if (common.userDetails !== "") {
      getCertificates(common.userDetails.its_id);
      getTimelines({
        user_id: common.userDetails.its_id,
        ver: common.userVer,
      });
      getBadges({
        user_id: common.userDetails.its_id,
        ver: common.userVer,
      });
      getGroupListFront({
        user_id: common.userDetails.its_id,
        ver: common.userVer,
      });
      getGroupInfo({
        label: "groups",
        user_id: common.userDetails.its_id,
        ver: common.userVer,
      });
      getFrontPromotionList({
        user_id: common.userDetails.its_id,
        ver: common.userVer,
      });
    }
  }, [common.userDetails]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  function LinearProgressWithLabel(props) {
    return (
      <Box
        display="flex"
        alignItems="center"
        className={classes.progressBorder}
      >
        <Box width="100%" style={{ width: 400 }} mr={1}>
          {props.value ? (
            <LinearProgress
              variant="determinate"
              value={Math.round(props.value)}
              {...props}
            />
          ) : (
            ""
          )}
        </Box>
        <Box minWidth={35}>
          <Typography variant="body2" color="textSecondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }

  return (
    <div>
      <Grid container spacing={2} className={classes.paddingGrid}>
        {/* First row */}
        <Grid item xs={12} md={8}>
          {/* Promotional box */}
          <div className={classes.promotionCard}>
            {common.frontPromotionList &&
              common.frontPromotionList.map((item, index) => {
                return (
                  <Accordion elevation={5} expanded={true} key={index}>
                    <AccordionSummary
                      className={classes.activeAccordion}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <Typography className={classes.heading}>
                          {item.name}
                        </Typography>
                        <div style={{ marginLeft: 10, display: "flex" }}>
                          {Number(item.show_marksheet) === 1 && (
                            <Link
                              target="_blank"
                              href={`https://imtihaan.talabulilm.com/marksheet/${item.id}/${item.slug}`}
                            >
                              <img
                                style={{ padding: " 0px 10px" }}
                                src={Certificate}
                                alt="Badge"
                              />
                            </Link>
                          )}
                          {item.certificate_id > 0 &&
                            item.certificate_status > 0 && (
                              <Link
                                target="_blank"
                                href={`https://www.talabulilm.com/istifaadah/certificate.php?cid=${item.certificate_id}`}
                              >
                                <img src={Scholar} alt="Badge" />
                              </Link>
                            )}
                        </div>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails
                      className={classes.activeAccordionDetails}
                    >
                      <div
                        style={{ minHeight: 180 }}
                        dangerouslySetInnerHTML={{ __html: item.description }}
                      />
                    </AccordionDetails>
                    <AccordionActions
                      className={classes.activeAccordionDetails}
                    >
                      {item.btn_status === "Closed" && (
                        <CustomButton
                          disabled
                          variant="contained"
                          color="secondary"
                          className={classes.inActiveAccordionButton}
                        >
                          Ended
                        </CustomButton>
                      )}
                      {item.btn_status === "Open" && (
                        <Link href={`/quizzes/${item.id}/${item.slug}`}>
                          <CustomButton
                            variant="contained"
                            color="primary"
                            className={classes.inActiveAccordionButton}
                          >
                            Attempt
                          </CustomButton>
                        </Link>
                      )}
                      {item.btn_status === "Will Start" && (
                        <CustomButton
                          disabled
                          variant="contained"
                          color="secondary"
                          className={classes.inActiveAccordionButton}
                        >
                          Coming soon
                        </CustomButton>
                      )}
                    </AccordionActions>
                  </Accordion>
                );
              })}
          </div>

          <Grid container spacing={2}>
            {/* Other courses */}
            <Grid item md={6} className={classes.hideOtherCourse}>
              {common.groupListFront.length > 0 &&
                common.groupListFront.map((group, index) => {
                  return (
                    <Accordion
                      key={index}
                      style={{
                        borderTopLeftRadius: index === 0 && 4,
                        borderTopRightRadius: index === 0 && 4,
                        borderBottomLeftRadius:
                          index === common.groupListFront.length - 1 &&
                          expanded !== `panel${index}` &&
                          4,
                        borderBottomRightRadius:
                          index === common.groupListFront.length - 1 &&
                          expanded !== `panel${index}` &&
                          4,
                      }}
                      expanded={expanded === `panel${index}`}
                      onChange={handleChange(`panel${index}`)}
                    >
                      <AccordionSummary
                        expandIcon={
                          <ExpandMoreIcon style={{ color: "#fff" }} />
                        }
                        className={classes.inActiveAccordion}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        style={{
                          borderTopLeftRadius: index === 0 && 4,
                          borderTopRightRadius: index === 0 && 4,
                          borderBottomLeftRadius:
                            index === common.groupListFront.length - 1 &&
                            expanded !== `panel${index}` &&
                            4,
                          borderBottomRightRadius:
                            index === common.groupListFront.length - 1 &&
                            expanded !== `panel${index}` &&
                            4,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <Typography className={classes.heading}>
                            {group.name}
                          </Typography>
                          <div style={{ marginLeft: 10, display: "flex" }}>
                            {group.show_marksheet === "1" && (
                              <Link
                                target="_blank"
                                href={`https://imtihaan.talabulilm.com/marksheet/${group.id}/${group.slug}`}
                              >
                                <img
                                  style={{ padding: " 0px 10px" }}
                                  src={Certificate}
                                  alt="Badge"
                                />
                              </Link>
                            )}
                            {group.certificate_id > 0 &&
                              group.certificate_status > 0 && (
                                <Link
                                  target="_blank"
                                  href={`https://www.talabulilm.com/istifaadah/certificate.php?cid=${group.certificate_id}`}
                                >
                                  <img src={Scholar} alt="Badge" />
                                </Link>
                              )}
                          </div>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails
                        className={classes.inActiveAccordionDetails}
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: group.description,
                          }}
                        />
                      </AccordionDetails>
                      <AccordionActions
                        className={classes.inActiveAccordionDetails}
                      >
                        {group.btn_status === "Closed" && (
                          <CustomButton
                            disabled
                            variant="contained"
                            color="secondary"
                            className={classes.inActiveAccordionButton}
                          >
                            Ended
                          </CustomButton>
                        )}
                        {group.btn_status === "Open" && (
                          <Link to={`/quizzes/${group.id}/${group.slug}`}>
                            <CustomButton
                              variant="contained"
                              color="secondary"
                              className={classes.inActiveAccordionButton}
                            >
                              Attempt
                            </CustomButton>
                          </Link>
                        )}
                        {group.btn_status === "Will Start" && (
                          <CustomButton
                            disabled
                            variant="contained"
                            color="secondary"
                            className={classes.inActiveAccordionButton}
                          >
                            Coming soon
                          </CustomButton>
                        )}
                      </AccordionActions>
                    </Accordion>
                  );
                })}
            </Grid>

            {/* My certificates Desktop */}
            <Grid item md={6} className={classes.hideOtherCourse}>
              <Card style={{ width: "100%", backgroundColor: "#457B9D" }}>
                <Typography
                  variant="h6"
                  style={{ padding: 20, color: "#F1FAEE" }}
                >
                  My Certificates
                </Typography>
              </Card>
              <List
                style={{ marginTop: "-10px", paddingBottom: 0 }}
                component="nav"
                aria-label="secondary mailbox folders"
              >
                {common.certificates.length > 0 ? (
                  common.certificates.map((cert, index) => {
                    return (
                      <ListItem
                        key={index}
                        style={{
                          borderBottomLeftRadius:
                            common.certificates.length - 1 === index && 4,
                          borderBottomRightRadius:
                            common.certificates.length - 1 === index && 4,
                        }}
                        button
                        component={"a"}
                        href={`https://www.talabulilm.com/istifaadah/certificate.php?cid=${cert.id}`}
                        target="_blank"
                        className={classes.timelineList}
                      >
                        <ListItemIcon>
                          <img
                            className={classes.certImage}
                            src={`https://www.talabulilm.com/istifaadah/upload/courses/certificate/${cert.icon}`}
                            alt="Badge"
                          />
                        </ListItemIcon>
                        <ListItemText primary={cert.title} />
                      </ListItem>
                    );
                  })
                ) : (
                  <Typography
                    variant="body1"
                    style={{
                      textAlign: "center",
                      color: "#999",
                      backgroundColor: "#fff",
                      padding: "20px",
                      borderBottomLeftRadius: "20px",
                      borderBottomRightRadius: "20px",
                    }}
                  >
                    No Certificates are found
                  </Typography>
                )}
              </List>
            </Grid>
          </Grid>
        </Grid>

        {/* second row */}
        <Grid item xs={12} md={4}>
          {/* Info box */}
          {common.groupInfo.text && common.groupInfo.text !== "" && (
            <div style={{ margin: "20px 0px 0px 0px" }}>
              <Accordion elevation={5} expanded={true}>
                <AccordionSummary
                  className={classes.inActiveAccordion}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>
                    IMPORTANT INSTRUCTIONS
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.inActiveAccordionDetails}>
                  <div
                    dangerouslySetInnerHTML={{ __html: common.groupInfo.text }}
                  ></div>
                </AccordionDetails>
              </Accordion>
            </div>
          )}
          {/* Profile details */}
          {/* <div style={{ margin: "20px 0px 20px 0px" }}>
            <Accordion elevation={5} expanded={true}>
              <AccordionSummary
                className={classes.activeAccordion}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  My Educational Journey
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                style={{ flexDirection: "column" }}
                className={classes.activeAccordionDetails}
              >
                <div
                  style={{ minHeight: 130 }}
                  dangerouslySetInnerHTML={{
                    __html: common.timelines.education_status,
                  }}
                />
                <LinearProgressWithLabel
                  color="secondary"
                  value={common.timelines.percent_complete}
                />
              </AccordionDetails>
              <AccordionActions className={classes.activeAccordionDetails}>
                <CustomButton
                  target="_blank"
                  href="https://www.talabulilm.com/profile/"
                  variant="contained"
                  color="primary"
                  className={classes.inActiveAccordionButton}
                >
                  Profile
                </CustomButton>
              </AccordionActions>
            </Accordion>
          </div> */}
          {/* <Grid
            item
            md={12}
            style={{ paddingTop: "20px" }}
            className={classes.hideOtherCourse}
          >
            <Card style={{ width: "100%", backgroundColor: "#457B9D" }}>
              <Typography
                variant="h6"
                style={{ padding: 20, color: "#F1FAEE" }}
              >
                My Badges
              </Typography>
            </Card>
            <List
              style={{ marginTop: "-10px", paddingBottom: 0 }}
              component="nav"
              aria-label="secondary mailbox folders"
            >
              {common.badges.map((badge, index) => {
                return (
                  <ListItem
                    key={index}
                    button
                    component={"a"}
                    href={badge.link} // Replace this with the actual link for the badge
                    target="_blank"
                    className={classes.timelineList}
                  >
                    <ListItemIcon>
                      <img
                        className={classes.certImage}
                        src={badge.image} // Assuming badges images are stored similarly
                        alt="Badge"
                      />
                    </ListItemIcon>
                    <ListItemText primary={badge.title} />
                  </ListItem>
                );
              })}
            </List>
          </Grid> */}

          <Grid item md={12} style={styles.container}>
            <Card
              style={{
                width: "100%",
                backgroundColor: "#457B9D",
                borderBottomLeftRadius: "0px",
                borderBottomRightRadius: "0px",
              }}
            >
              <Typography
                variant="h6"
                style={{ padding: 18, color: "#F1FAEE" }}
              >
                My Online Imtihaan 1446H Badges
              </Typography>
            </Card>

            <Typography style={styles.badgeWrapper}>
              Click here to{" "}
              <a
                href="https://drive.google.com/file/d/1cFjKucfbbtwPBeATB-1WG9znMIbShHbF/view?usp=sharing"
                target="_blank"
                rel="noopener noreferrer"
                style={styles.badgeLink}
              >
                View Badge Details and Unlocking Requirements Here
              </a>
            </Typography>

            <div style={styles.grid}>
              {Array.isArray(common.badges) && common.badges.length > 0 ? (
                common.badges.map((badge, index) => {
                  const isClaimed = badge.is_assigned;

                  return (
                    <a
                      key={index}
                      onClick={() => isClaimed && handleOpen(badge)}
                      style={{
                        cursor: isClaimed ? "pointer" : "not-allowed",
                        textDecoration: "none",
                        opacity: isClaimed ? 1 : 0.5,
                      }}
                    >
                      <Card style={styles.badgeCard}>
                        <div style={styles.imageContainer}>
                          <img
                            src={badge.image}
                            alt={badge.name || "Badge"}
                            style={{
                              paddingTop: "10px",
                              width: "100%",
                              height: "100%",
                              objectFit: "contain",
                              filter: isClaimed ? "none" : "grayscale(100%)", // Apply grayscale for unclaimed badges
                            }}
                          />
                        </div>

                        <div style={styles.nameContainer}>
                          <Typography
                            variant="body1"
                            style={{
                              paddingLeft: 10,
                              paddingRight: 10,
                              textAlign: "center",
                              fontSize: 12,
                              fontFamily: "Arial",
                              color: "#999",
                            }}
                          >
                            {badge?.name}
                          </Typography>
                        </div>
                      </Card>
                    </a>
                  );
                })
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    width: "100%",
                  }}
                >
                  <Typography
                    variant="body1"
                    style={{
                      width: "350px",
                      textAlign: "center",
                      color: "#999",
                    }}
                  >
                    No badges are found
                  </Typography>
                </div>
              )}
            </div>
          </Grid>

          {selectedBadge && (
            <Dialog
              open={open}
              onClose={handleClose}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                maxWidth: "100%",
                margin: 0, // Remove margin to prevent extra space
              }}
            >
              <div
                style={{
                  backgroundColor: "#F1FAEE",
                  width: "100%", // Ensure full width on smaller screens
                  maxWidth: "500px", // Limit max width on larger screens
                  borderRadius: "10px", // Optional: adds rounded corners to the dialog
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <DialogTitle
                    style={{
                      fontSize: "28px",
                      color: "rgb(32, 53, 118)",
                      fontWeight: "bolder",
                      overflowWrap: "break-word", // Ensures the title doesn't overflow
                      wordBreak: "break-word",
                      fontSize: "calc(1.5rem + 1vw)", // Make the title responsive
                    }}
                  >
                    {selectedBadge?.name}
                  </DialogTitle>
                  <DialogActions>
                    <Button onClick={handleClose} color="primary">
                      <Close />
                    </Button>
                  </DialogActions>
                </div>
                <DialogContent style={{ padding: "20px" }}>
                  <img
                    src={selectedBadge.image}
                    alt={selectedBadge.name || "Badge"}
                    style={{
                      width: "100%",
                      height: "200px", // Let the height adjust automatically
                      objectFit: "contain", // Keeps the image proportional
                    }}
                  />
                  <Typography
                    variant="body2"
                    style={{
                      marginTop: "20px",
                      fontSize: "18px",
                      color: "rgb(142, 119, 24)",
                      wordWrap: "break-word", // Prevents long words from breaking the layout
                    }}
                  >
                    {selectedBadge?.description || "No description available."}
                  </Typography>
                </DialogContent>
              </div>
            </Dialog>
          )}

          {/* Confetti Triggered when the Dialog is open */}
          {open && (
            <Confetti
              width={window.innerWidth} // Adjust the width dynamically to fit the screen
              height={window.innerHeight} // Adjust the height dynamically to fit the screen
              recycle={false} // To ensure confetti doesn't repeat
              numberOfPieces={1500}
              gravity={0.4}
              style={{
                position: "fixed", // Make sure it is positioned relative to the viewport
                top: 0,
                left: 0,
                zIndex: 9999, // Ensure the confetti is on top of other content
              }}
            />
          )}

          {/* Timeline list */}
          {/* <div>
            <Card
              style={{
                width: "100%",
                backgroundColor: "#457B9D",
                paddingBottom: 0,
              }}
            >
              <Typography
                variant="h6"
                style={{ padding: 20, color: "#F1FAEE" }}
              >
                My Education Timeline
              </Typography>
            </Card>
            <List
              style={{ marginTop: "-10px", paddingBottom: 0 }}
              component="nav"
              aria-label="secondary mailbox folders"
            >
              {common.timelines &&
                common.timelines.timeline &&
                common.timelines.timeline.map((tm, index) => {
                  return (
                    <ListItem
                      key={index}
                      style={{
                        backgroundColor: tm.status === "Pending" && "#b4d0d1",
                        borderBottomLeftRadius:
                          common.timelines.timeline.length - 1 === index && 4,
                        borderBottomRightRadius:
                          common.timelines.timeline.length - 1 === index && 4,
                      }}
                      button={tm.status === "Pending"}
                      component={tm.status === "Pending" ? "a" : ""}
                      target="_blank"
                      href="https://www.talabulilm.com/profile/"
                      className={classes.timelineList}
                    >
                      <ListItemIcon>
                        {tm.status === "Pending" ? (
                          <ClearIcon style={{ color: "#7D3424" }} />
                        ) : (
                          <CheckIcon style={{ color: "#247D47" }} />
                        )}
                      </ListItemIcon>
                      {tm.status !== "Pending" ? (
                        <ListItemText
                          primary={tm.marhala_text}
                          secondary={`${tm.status}`}
                          className={classes.dark}
                        />
                      ) : (
                        <ListItemText
                          primary={tm.marhala_text}
                          secondary="Click here to update your education details"
                        />
                      )}
                    </ListItem>
                  );
                })}
            </List>
          </div> */}
        </Grid>

        {/* Third row */}
        <Grid item xs={12} md={4}>
          {/* My certificates Mobile */}
          <div
            className={classes.showOtherCourse}
            style={{ margin: "20px 0px 20px 0px" }}
          >
            <Card style={{ width: "100%", backgroundColor: "#457B9D" }}>
              <Typography
                variant="h6"
                style={{ padding: 20, color: "#F1FAEE" }}
              >
                My Certificates
              </Typography>
            </Card>
            <List
              style={{ marginTop: "-10px", paddingBottom: 0 }}
              component="nav"
              aria-label="secondary mailbox folders"
            >
              {common.certificates.length > 0 &&
                common.certificates.map((cert, index) => {
                  return (
                    <ListItem
                      key={index}
                      style={{
                        borderBottomLeftRadius:
                          common.certificates.length - 1 === index && 4,
                        borderBottomRightRadius:
                          common.certificates.length - 1 === index && 4,
                      }}
                      button
                      component={"a"}
                      href={`https://www.talabulilm.com/istifaadah/certificate.php?cid=${cert.id}`}
                      target="_blank"
                      className={classes.timelineList}
                    >
                      <ListItemIcon>
                        <img
                          className={classes.certImage}
                          src={`https://www.talabulilm.com/istifaadah/upload/courses/certificate/${cert.icon}`}
                          alt="Badge"
                        />
                      </ListItemIcon>
                      <ListItemText primary={cert.title} />
                    </ListItem>
                  );
                })}
            </List>
          </div>

          {/* Other courses mobile */}
          <div className={classes.showOtherCourse}>
            {common.groupListFront.length > 0 &&
              common.groupListFront.map((group, index) => {
                return (
                  <Accordion
                    key={index}
                    expanded={expanded === `panel${index}`}
                    onChange={handleChange(`panel${index}`)}
                    style={{
                      borderTopLeftRadius: index === 0 && 4,
                      borderTopRightRadius: index === 0 && 4,
                      borderBottomLeftRadius:
                        index === common.groupListFront.length - 1 &&
                        expanded !== `panel${index}` &&
                        4,
                      borderBottomRightRadius:
                        index === common.groupListFront.length - 1 &&
                        expanded !== `panel${index}` &&
                        4,
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}
                      className={classes.inActiveAccordion}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      style={{
                        borderTopLeftRadius: index === 0 && 4,
                        borderTopRightRadius: index === 0 && 4,
                        borderBottomLeftRadius:
                          index === common.groupListFront.length - 1 &&
                          expanded !== `panel${index}` &&
                          4,
                        borderBottomRightRadius:
                          index === common.groupListFront.length - 1 &&
                          expanded !== `panel${index}` &&
                          4,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <Typography className={classes.heading}>
                          {group.name}
                        </Typography>
                        <div style={{ marginLeft: 10, display: "flex" }}>
                          {group.show_marksheet === "1" && (
                            <Link
                              target="_blank"
                              href={`https://imtihaan.talabulilm.com/marksheet/${group.id}/${group.slug}`}
                            >
                              <img src={Certificate} alt="Badge" />
                            </Link>
                          )}
                          {group.certificate_id > 0 &&
                            group.certificate_status > 0 && (
                              <Link
                                target="_blank"
                                href={`https://www.talabulilm.com/istifaadah/certificate.php?cid=${group.certificate_id}`}
                              >
                                <img src={Scholar} alt="Badge" />
                              </Link>
                            )}
                        </div>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails
                      className={classes.inActiveAccordionDetails}
                    >
                      <div
                        dangerouslySetInnerHTML={{ __html: group.description }}
                      />
                    </AccordionDetails>
                    <AccordionActions
                      className={classes.inActiveAccordionDetails}
                    >
                      {group.btn_status === "Closed" && (
                        <CustomButton
                          disabled
                          variant="contained"
                          color="secondary"
                          className={classes.inActiveAccordionButton}
                        >
                          Ended
                        </CustomButton>
                      )}
                      {group.btn_status === "Open" && (
                        <Link to={`/quizzes/${group.id}/${group.slug}`}>
                          <CustomButton
                            variant="contained"
                            color="secondary"
                            className={classes.inActiveAccordionButton}
                          >
                            Attempt
                          </CustomButton>
                        </Link>
                      )}
                      {group.btn_status === "Will Start" && (
                        <CustomButton
                          disabled
                          variant="contained"
                          color="secondary"
                          className={classes.inActiveAccordionButton}
                        >
                          Coming soon
                        </CustomButton>
                      )}
                    </AccordionActions>
                  </Accordion>
                );
              })}
          </div>
        </Grid>
      </Grid>

      {/* <iframe
        src={'sample.pdf'}
        height={854}
        width={"100%"}
      /> */}
      <Footer />
    </div>
  );
}
