import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { FaDownload, FaEye } from "react-icons/fa";
import { useParams } from "react-router-dom";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const columns = [
  { id: "name", label: "User Name" },
  { id: "jamaat", label: "Jamaat" },
  { id: "jamiat", label: "Jamiat" },
  { id: "phone", label: "Phone" },
  { id: "city", label: "City" },
  { id: "country", label: "Country" },
  { id: "totalScore", label: "Score" },
  { id: "action", label: "Action" },
];

function UserListTable({ groupId }) {
  const { quizId } = useParams();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(true);
  const [url3Data, setUrl3Data] = useState([]);
  const baseUrl = "https://talabulilm.com/imtihaan/imtihaan-api/api/get/";
  useEffect(() => {
    const fetchUrlData = async () => {
      if (!quizId) {
        return;
      }

      try {
        const url3 = `${baseUrl}user_list/${quizId}/${groupId}/all`;
        const response3 = await fetch(url3);
        const data3 = await response3.json();
        setUrl3Data(data3.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchUrlData();
  }, []);
  // --------------CSV-----------------------------
  const downloadCsv = () => {
    const csvContent =
      "data:text/csv;charset=utf-8," +
      url3Data.map((row) => Object.values(row).join(",")).join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "jamaat-report.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  // --------------Filter-----------------------------
  const [filteredData, setFilteredData] = useState([]);

  const handleFilterChange = (event, columnId) => {
    const inputValue = event.target.value.toLowerCase();
    const filteredRows = url3Data.filter((row) =>
      row[columnId].toLowerCase().includes(inputValue)
    );
    setFilteredData(filteredRows);
  };
  //---------------------------------------------------
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleClick = (its_id) => {
    const url = `/admin/marksheet/${groupId}/${its_id}`;
    window.open(url, "_blank");
  };

  // console.log(url3Data, "ul");
  return (
    <>
      <div className="container-admin admin-table">
        {url3Data ? (
          <Paper>
            <div>
              <span
                style={{
                  opacity: "0.8",
                  margin: "20px",
                  fontWeight: "bold",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                User List
              </span>
              <hr className="underline"></hr>
            </div>

            <>
              <span
                onClick={downloadCsv}
                style={{
                  fontSize: "0.9em",
                  cursor: "pointer",
                  marginLeft: "20px",
                  marginTop: "10px",
                  color: "green",
                }}
              >
                <FaDownload size={15} color="green" /> Download Csv
              </span>
              <TableContainer sx={{ maxHeight: 800 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align="left"
                          style={{
                            minWidth: 150,
                            fontSize: "19px",
                            color: "blue",
                          }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell key={column.id} align="left">
                          <TextField
                            id={column.id + "-filter"}
                            label={`Filter ${column.label}`}
                            variant="outlined"
                            size="small"
                            onChange={(event) =>
                              handleFilterChange(event, column.id)
                            }
                            style={{
                              marginLeft: "10px",
                              borderBottom: "1px solid red",
                            }}
                          />
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(filteredData.length > 0 ? filteredData : url3Data)
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const action = (
                          <>
                            <FaEye
                              size={17}
                              alt="eyelogo"
                              color="blue"
                              className="icon"
                              style={{ marginRight: "10px" }}
                              onClick={() => handleClick(row.its_id)}
                            />
                          </>
                        );
                        return (
                          <TableRow hover key={index}>
                            <TableCell align="left" style={{ padding: "10px" }}>
                              {row.name}
                            </TableCell>
                            <TableCell align="left">{row.jamaat}</TableCell>
                            <TableCell align="left">{row.jamiat}</TableCell>
                            <TableCell align="left">{row.phone}</TableCell>
                            <TableCell align="left">{row.city}</TableCell>
                            <TableCell align="left">{row.country}</TableCell>
                            <TableCell align="left">{row.totalScore}</TableCell>
                            <TableCell align="left">{action}</TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <br />
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="span"
                count={url3Data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelDisplayedRows={({ from, to, count }) => {
                  return (
                    <span
                      style={{
                        color: "blue",
                        fontWeight: "bold",
                        display: "flex",
                      }}
                    >
                      {`${from}-${to} of ${count}`}
                    </span>
                  );
                }}
                sx={{
                  margin: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  "& .MuiButtonBase-root": {
                    borderRadius: "20px",
                    padding: "10px",
                    marginRight: "10px",
                  },
                  "& .MuiSelect-selectMenu": {
                    color: "red",
                    paddingRight: "10px",
                  },
                  "& .MuiTablePagination-actions button": {
                    backgroundColor: "#545cd8",
                    gap: "10px",
                    padding: "8px",
                    color: "white",
                    borderRadius: "5px",
                  },
                }}
              />
            </>
          </Paper>
        ) : (
          <div>No Data</div>
        )}
      </div>

      <ToastContainer />
    </>
  );
}
export default UserListTable;
