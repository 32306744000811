export const SET_TIMELINES = "SET_TIMELINES";
export const SET_CERTIFICATES = "SET_CERTIFICATES";
export const SET_BADGES = "SET_BADGES";
export const SET_GROUP_LIST_FRONT = "SET_GROUP_LIST_FRONT";
export const SET_GROUP_INFO = "SET_GROUP_INFO";
export const SET_QUIZ_INFO = "SET_QUIZ_INFO";
export const SET_LANGUAGES = "SET_LANGUAGES";
export const SET_FRONT_QUIZ_LIST = "SET_FRONT_QUIZ_LIST";
export const SET_FRONT_PROMOTION_LIST = "SET_FRONT_PROMOTION_LIST";

export const SET_USER_DETAILS = "SET_USER_DETAILS";
export const SET_VER = "SET_VER";
export const SET_MARK_LIST = "SET_MARK_LIST";
export const SET_TOTAL_MARK = "SET_TOTAL_MARK";
export const SET_GROUP_DATA = "SET_GROUP_DATA";
export const SET_RANK = "SET_RANK";

export const SET_LEADERBOARD_DATA = "SET_LEADERBOARD_DATA";
export const SET_JAMIAT_LEADERBOARD_DATA = "SET_JAMIATLEADERBOARD_DATA";
export const SET_JAMAAT_LEADERBOARD_DATA = "SET_JAMAATLEADERBOARD_DATA";

export const IMAGES = [
  {
    src: "/images/Imtihaan_1445H_badge_1.png",
    alt: "Speedster Badge",
    title: "Speedster",
    description: "Awarded for completing a paper in under five minutes.",
  },
  {
    src: "/images/Imtihaan_1445H_badge_2.png",
    alt: "Accuracy Ace Badge",
    title: "Accuracy Ace",
    description: "Earned by scoring a perfect 100% on any given paper.",
  },
  {
    src: "/images/Imtihaan_1445H_badge_3.png",
    alt: "Early Bird Badge",
    title: "Early Bird",
    description: "For attempting a paper on the same day it becomes live.",
  },
  {
    src: "/images/Imtihaan_1445H_badge_4.png",
    alt: "Consistent Performer Badge",
    title: "Consistent Performer",
    description: "For achieving a score of over 80% in three or more papers.",
  },
  {
    src: "/images/Imtihaan_1445H_badge_5.png",
    alt: "Perfectionist Badge",
    title: "Perfectionist",
    description: "Awarded to students who score 100% on all their papers.",
  },
  {
    src: "/images/Imtihaan_1445H_badge_6.png",
    alt: "Imtihaan Marathoner Badge",
    title: "Imtihaan Marathoner",
    description:
      "For attempting 10 papers over 10 consecutive days (one paper per day).",
  },
  {
    src: "/images/Imtihaan_1445H_badge_7.png",
    alt: "Feedback Maestro Badge",
    title: "Feedback Maestro",
    description:
      "Earned by providing feedback on any online Imtihaan activities through the designated feedback forms.",
  },
  {
    src: "/images/Imtihaan_1445H_badge_8.png",
    alt: "Steady Learner Badge",
    title: "Steady Learner",
    description: "For completing a paper in over 20 minutes.",
  },
  {
    src: "/images/Imtihaan_1445H_badge_9.png",
    alt: "Leaderboard Topper Badge",
    title: "Leaderboard Topper",
    description:
      "For students who rank in the top 10 of any leaderboard (global or local).",
  },
  {
    src: "/images/Imtihaan_1445H_badge_10.png",
    alt: "Legacy Learner Badge",
    title: "Legacy Learner",
    description:
      "Awarded to students who have a family member also participating in Online Imtihaan.",
  },
];
