import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getGroupData,
  getMarkList,
  getRank,
  getTotalMark,
} from "../../actions/marksheetAction/marksheetAction";
import Marksheet from "./../../components/marksheet/marksheet";

export class MarksheetCont extends Component {
  render() {
    return <Marksheet {...this.props} />;
  }
}

export const mapStateToProps = (store) => {
  return {
    common: store.common,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    getGroupData: (id) => {
      dispatch(getGroupData(id));
    },
    getMarkList: (user_id, id, isAdmin) => {
      dispatch(getMarkList(user_id, id, isAdmin));
    },
    getRank: (user_id, id, isAdmin) => {
      dispatch(getRank(user_id, id, isAdmin));
    },
    getTotalMark: (user_id, id, isAdmin) => {
      dispatch(getTotalMark(user_id, id, isAdmin));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MarksheetCont);
