import React, { useEffect, useState } from "react";
var base64 = require("base-64");

export default function Controller(props) {
  const [redirect, setRedirect] = useState("");
  const loginRedirect = `https://www.talabulilm.com/send_2_onelogin.php?r=${base64.encode(
    "imtihaan.talabulilm.com"
  )}`;

  const { setUserDetails, getLoginUserData, setUserVer } = props;

  useEffect(() => {
    var pairs = document.cookie.split(";");
    var cookies = {};
    for (var i = 0; i < pairs.length; i++) {
      var pair = pairs[i].split("=");
      cookies[(pair[0] + "").trim()] = unescape(pair.slice(1).join("="));
    }

    if (typeof cookies["user_its"] === "undefined") {
      setRedirect(true);
      return; // Do not return anything here
    } else {
      if (localStorage.getItem("userData2") === null) {
        getLoginUserData(cookies["user_its"]);
        setUserVer(cookies["ver"]);
      } else {
        let currentLocalUser = JSON.parse(localStorage.getItem("userData2"));
        if (currentLocalUser.its_id !== cookies["user_its"]) {
          getLoginUserData(cookies["user_its"]);
          setUserVer(cookies["ver"]);
        } else {
          setUserDetails(JSON.parse(localStorage.getItem("userData2")));
          setUserVer(cookies["ver"]);
        }
      }
    }
  }, [getLoginUserData, setUserDetails, setUserVer]);
  // console.log(props, "props");
  if (redirect) {
    window.location.href = loginRedirect;
    return (
      <div>
        <p>Redirecting...</p>
      </div>
    );
  }
  return <div></div>;
}
