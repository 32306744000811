import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Tabs,
  Tab,
  Typography,
  Box,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionActions,
  Dialog,
  DialogContent,
  DialogActions,
  Slide,
  IconButton,
  Paper,
} from "@material-ui/core";
import { useMediaQuery } from "@material-ui/core";
import {
  Table,
  TableContainer,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
} from "@material-ui/core";
import CustomButton from "../UI/button";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import LinearProgress from "@material-ui/core/LinearProgress";
import html2canvas from "html2canvas";
import ShareIcon from "@material-ui/icons/Share";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import axios from "axios";

const base64 = require("base-64");

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:hover": {
      backgroundColor: "#23C4ED33",
    },
  },
}))(TableRow);
const useStyles = makeStyles((theme) => ({
  inActiveAccordion: {
    backgroundColor: "#457B9D",
    color: "#F1FAEE",
    textTransform: "uppercase",
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  inActiveAccordionDetails: {
    backgroundColor: "#F1FAEE",
    color: "#457B9D",
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
  userName: {
    position: "absolute",
    marginTop: "71px",
    marginLeft: "40px",
    color: "white",
    fontSize: "14px",
    width: "200px",
    textAlign: "center",
    fontWeight: 600,
  },
  inActiveAccordionButton: {
    borderRadius: 5,
    border: "1px solid #709395",
    textTransform: "none",
    fontWeight: "bold",
    textDecoration: "none",
    marginBottom: 10,
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    // border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  gridItem: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      paddingTop: "15px",
      justifyContent: "right",
    },
  },
  mainContainer: {
    padding: 25,
  },
  marksheetMarks: {
    fontSize: "1.6rem",
    color: "#6c757d",
    fontWeight: "700",
  },
  description: {
    fontSize: ".88rem",
    color: "#495057",
    fontWeight: "400",
    opacity: "0.5",
  },
  percentText: {
    color: "#83588a",
    fontSize: "1.6rem",
    fontWeight: "700",
  },
  rankTitle: {
    fontSize: "1.6rem ",
    color: "#83588a",
    fontWeight: "700",
  },
  border: {
    height: 1,
    width: "100%",
    backgroundColor: "gray",
    opacity: 0.3,
  },
  noteText: {
    fontSize: 14,
    color: "gray",
  },
  tableContainer: {
    maxWidth: "100%",
    height: "750px",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: "10px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#457B9D",
      borderRadius: "10px",
      border: "3px solid #f1f1f1",
      height: "90px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#1d3557",
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0} style={{ paddingRight: 0, paddingLeft: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

// Helper function to determine screen size
const getGridLayout = () => ({
  display: "grid",
  gridTemplateColumns: {
    xs: "1fr",
    sm: "1fr",
    md: "60% 40%",
  },
  gridTemplateAreas: {
    xs: `"cards" "leaderboard" `,
    sm: `"cards" "leaderboard" `,
    md: `"cards leaderboard"`,
  },
  gap: {
    xs: "10px",
    sm: "15px",
    md: "20px",
  },
  width: "100%",
  padding: {
    xs: "10px",
    sm: "15px",
    md: "20px",
  },
  overflow: "hidden",
  maxWidth: "100vw",
});

const getLeaderboardStyles = () => ({
  gridArea: "leaderboard",
  margin: {
    xs: 1,
    sm: 2,
    md: 3,
  },
  padding: {
    xs: "10px",
    sm: "15px",
    md: "20px",
  },
  width: "100%",
  maxWidth: "100%",
  overflow: "hidden", // Keep container from scrolling
  "& .table-container": {
    width: "100%",
    overflow: "auto", // Enable horizontal scroll
    "&::-webkit-scrollbar": {
      height: "8px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#888",
      borderRadius: "4px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  },
  "& table": {
    minWidth: "100%", // Ensure table takes at least full width
    width: "max-content", // Allow table to grow based on content
    "& td, & th": {
      whiteSpace: "nowrap", // Prevent text wrapping
      padding: "16px 16px", // Add some padding for readability
    },
  },
});

const getCardsStyles = () => ({
  gridArea: "cards",
  padding: {
    xs: "5px",
    sm: "10px",
    md: "20px",
  },
  width: "100%",
  maxWidth: "100%",
  overflow: "hidden",
});

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#1a90ff",
  },
}))(LinearProgress);

export default function Quiz(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [totalAttemptedCount, setTotalAttemptedCount] = useState();
  const [totalCount, setTotalCount] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const { common, getLeaderboard, getJamiatLeaderboard, getJamaatLeaderboard } =
    props;
  const [modalStyle] = React.useState(getModalStyle);
  const [activeTab, setActiveTab] = useState(0);
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isMobile = useMediaQuery("(max-width: 600px)");

  const [image, setImage] = useState(null);
  const [shareClicked, setShareClicked] = useState(false);

  //image load dynamic
  useEffect(() => {
    const fetchImage = async () => {
      console.log("totalAttemptedCount", totalAttemptedCount);
      try {
        const response = await import(
          `../../images/Imtihaan_1445H_badge_${totalAttemptedCount}.png`
        ); // change relative path to suit your needs
        setImage(response.default);
      } catch (err) {
        console.log("err: ", err);
      }
    };
    totalAttemptedCount && fetchImage();
  }, [totalAttemptedCount]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const { match, getFrontQuizList, getLanguages } = props;

  useEffect(() => {
    console.log("dddg", common.userDetails, common.userVer);

    const createLog = async () => {
      try {
        const res = await axios.post(
          `https://www.talabulilm.com/api2022/imtihaan/user/addLog/${match.params.id}`,
          {},
          {
            headers: {
              Authorization:
                "Basic " +
                base64.encode(common.userDetails.its_id + ":" + common.userVer),
            },
          }
        );
        if (res.data) {
          console.info("Log updated");
        }
      } catch (error) {
        console.error("Log not updated", error);
      }
    };

    common.userVer && common.userDetails.its_id && createLog();
  }, [common.userVer, common.userDetails.its_id]);

  useEffect(() => {
    getFrontQuizList(common.userDetails.its_id, match.params.id);
    getLanguages();
  }, [common.userDetails]);

  useEffect(() => {
    if (common) {
      const data = common?.frontQuizList[common?.languages[value]?.code];
      setTotalAttemptedCount(
        data?.filter((item) => item.attended === "1").length
      );
      // console.log(data?.attended);
      setTotalCount(data?.length);
    }
  }, [common, value]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleShare = () => {
    setShareClicked(true);
    const shareContent = document.getElementById("shareContent");

    if (!shareContent) {
      console.error("Element with ID 'shareContent' not found.");
      return;
    }

    html2canvas(shareContent)
      .then((canvas) => {
        const imgData = canvas.toBlob((blob) => {
          const files = [
            new File([blob], "talabulilm_imtihaan.png", { type: "image/png" }),
          ];

          navigator
            .share({
              text: "Check out this image and text: " + window.location.href,
              files,
            })
            .catch((error) => {
              console.error("Error sharing via Web Share API:", error);
            });
        }, "image/png");
      })
      .catch((error) => {
        console.error("Error capturing canvas:", error);
      });
  };

  const handleDownload = () => {
    html2canvas(document.getElementById("shareContent")).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");

      // Create a temporary link and trigger a click to simulate download
      const tempLink = document.createElement("a");
      tempLink.href = imgData;
      tempLink.download = "talabulilm_imtihaan.png";
      tempLink.click();
    });
  };

  useEffect(() => {
    getLeaderboard(common.userDetails.its_id, props.match.params.id);
    getJamiatLeaderboard(props.match.params.id, common.userDetails.jamiat);
    getJamaatLeaderboard(props.match.params.id, common.userDetails.jamaat);
  }, [common.userDetails]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const renderTable = (data) => {
    return (
      <>
        <Grid item xs={12} style={{ marginTop: "-22px" }}>
          <Box>
            {/* Header */}
            <Box style={{ borderBottom: "1px solid gray" }}>
              <Typography
                style={{
                  textTransform: "uppercase",
                  fontWeight: "normal",
                  padding: "20px",
                  backgroundColor: "#457B9D",
                  color: "#fff",
                  textAlign: "center",
                  fontSize: isMobile ? "14px" : "16px", // Adjust font size for mobile
                }}
              >
                {props.match.params.slug}
              </Typography>
            </Box>

            {/* Table Container */}
            <TableContainer
              component={Paper}
              className={classes.tableContainer}
              style={{
                maxHeight: isMobile ? "300px" : "500px", // Reduce height on mobile
                maxWidth: isMobile ? "100%" : "auto", // Full width on mobile
                overflowY: "auto",
                borderRadius: "8px",
                borderTopLeftRadius: "0px",
                border: "1px solid #ddd",
                overflowX: "auto",
                padding: isMobile ? "0 10px" : "0", // Add padding on mobile for better spacing
              }}
            >
              <h5
                style={{
                  padding: "0.6rem 0.8rem",
                  fontWeight: 600,
                  letterSpacing: 0.6,
                  background: "#ffffff",
                  fontSize: "0.8rem",
                }}
              >
                Note:{" "}
                <span style={{ fontSize: "0.72rem", fontWeight: 400 }}>
                  This leaderboard is updated every hour. If you've submitted a
                  paper and your score isn't showing yet, don't worry! It will
                  appear on the leaderboard during the next hourly update.
                </span>
              </h5>
              {/* Tabs */}
              <Tabs
                value={activeTab}
                onChange={handleTabChange}
                textColor="primary"
                TabIndicatorProps={{
                  style: { height: "3px", backgroundColor: "#457B9D" },
                }}
                style={{
                  minHeight: "30px",
                  display: isMobile ? "block" : "flex", // Stack tabs on mobile
                  overflowX: "auto", // Allow horizontal scroll on mobile
                }}
              >
                {["WorldWide", "Jamiat", "Jamaat"].map((label, index) => (
                  <Tab
                    key={index}
                    label={label}
                    style={{
                      minWidth: "75px",
                      color: "#457B9D",
                      fontSize: "12px",
                      fontWeight: "bold",
                      padding: isMobile ? "5px 0" : "10px 15px", // Adjust padding for mobile
                    }}
                  />
                ))}
              </Tabs>

              {/* Table */}
              <Table stickyHeader aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    {["Rank", "User Name", "Age", "Score"].map(
                      (header, index) => (
                        <TableCell
                          key={index}
                          style={{
                            color: "#457B9D",
                            fontWeight: "bold",
                            whiteSpace: "nowrap",
                            fontSize: isMobile ? "12px" : "14px", // Adjust font size for mobile
                          }}
                        >
                          {header}
                        </TableCell>
                      )
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((user, index) => {
                    const isLoggedInUser =
                      user.its_id === common.userDetails.its_id;
                    return (
                      <StyledTableRow
                        key={index}
                        style={{
                          ...(!isLoggedInUser && {
                            backgroundColor:
                              index % 2 === 0 ? "#ffffff" : "#f9f9f9",
                            transition: "background-color 0.2s ease-in-out",
                          }),
                          ...(isLoggedInUser && {
                            backgroundColor: "#e0f7fa",
                            fontWeight: "bold",
                            transition: "background-color 0.2s ease-in-out",
                          }),
                          cursor: "pointer",
                        }}
                        onMouseEnter={(e) => {
                          e.currentTarget.style.backgroundColor = isLoggedInUser
                            ? "#b2ebf2"
                            : "#f2f2f2";
                        }}
                        onMouseLeave={(e) => {
                          e.currentTarget.style.backgroundColor = isLoggedInUser
                            ? "#e0f7fa"
                            : index % 2 === 0
                            ? "#ffffff"
                            : "#f9f9f9";
                        }}
                      >
                        {/* Rank */}
                        <TableCell
                          style={{
                            color: user.rank < 1 ? "#bda420" : "inherit",
                            fontWeight: user.rank < 1 ? "bold" : "normal",
                          }}
                        >
                          {user.rank < 1 ? "GOLD LEAGUE" : user.rank}
                        </TableCell>

                        {/* User Name */}
                        <TableCell
                          style={{
                            maxWidth: "200px", // Limiting width for mobile
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            fontWeight: isLoggedInUser ? "bold" : "normal",
                            fontSize: isMobile ? "12px" : "14px", // Adjust font size for mobile
                          }}
                        >
                          {user.name}
                        </TableCell>

                        {/* Age */}
                        <TableCell
                          style={{ fontSize: isMobile ? "12px" : "14px" }}
                        >
                          {user.age}
                        </TableCell>

                        {/* Score */}
                        <TableCell
                          style={{ fontSize: isMobile ? "12px" : "14px" }}
                        >
                          {user.score}
                        </TableCell>
                      </StyledTableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
      </>
    );
  };

  return (
    <>
      <AppBar position="static" style={{ background: "#457B9D" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          {common.languages &&
            common.languages.map((lang, i) => (
              <Tab label={lang.title} {...a11yProps(i)} />
            ))}
        </Tabs>
      </AppBar>

      <Box sx={getGridLayout()}>
        {/* Leaderboard Section */}
        <Box sx={getLeaderboardStyles()}>
          <Box mt={2}>
            {activeTab === 0 && renderTable(common.leaderboardData)}
            {activeTab === 1 && renderTable(common.jamiatLeaderboardData || [])}
            {activeTab === 2 && renderTable(common.jamaatLeaderboardData || [])}
          </Box>
        </Box>

        {/* Quiz Cards Section */}
        <Box sx={getCardsStyles()}>
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value}
            onChangeIndex={handleChangeIndex}
            resistance
            enableMouseEvents
            style={{ width: "100%", overflow: "hidden" }}
          >
            <div style={{ overflow: "hidden" }}>
              {common.languages &&
                common.languages?.map((lang, i) => {
                  const quizzes = common.frontQuizList?.[lang.code] || [];
                  return (
                    <TabPanel
                      value={value}
                      index={i}
                      dir={theme.direction}
                      key={lang.code}
                      style={{ overflow: "hidden" }}
                    >
                      <Grid
                        container
                        spacing={3} // Fixed spacing for consistent gaps
                        sx={{
                          margin: "0 !important", // Remove default margin
                          width: "100%",
                          overflow: "hidden",
                        }}
                      >
                        {quizzes.map((quiz) => (
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            key={quiz.id}
                            sx={{
                              padding: "12px !important", // Consistent padding for grid items
                            }}
                          >
                            <Box
                              sx={{
                                height: "100%",
                                overflow: "hidden",
                              }}
                            >
                              <Accordion
                                expanded={true}
                                sx={{
                                  overflow: "hidden",
                                  "& .MuiAccordionSummary-content": {
                                    margin: { xs: "10px 0", md: "12px 0" },
                                  },
                                  height: "100%", // Ensure full height
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <AccordionSummary
                                  className={classes.inActiveAccordion}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                >
                                  <Typography
                                    className={classes.heading}
                                    sx={{
                                      fontSize: {
                                        xs: "0.9rem",
                                        sm: "1rem",
                                        md: "1.1rem",
                                      },
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    {quiz.name}
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails
                                  className={classes.inActiveAccordionDetails}
                                  sx={{
                                    padding: {
                                      xs: "8px",
                                      sm: "16px",
                                      md: "16px 24px",
                                    },
                                    overflow: "hidden",
                                    flex: 1, // Take remaining space
                                  }}
                                >
                                  <div
                                    style={{
                                      overflow: "hidden",
                                      wordWrap: "break-word",
                                    }}
                                    dangerouslySetInnerHTML={{
                                      __html: quiz.description,
                                    }}
                                  />
                                </AccordionDetails>
                                <AccordionActions
                                  className={classes.inActiveAccordionDetails}
                                  sx={{
                                    padding: {
                                      xs: "8px",
                                      sm: "12px",
                                      md: "16px",
                                    },
                                    justifyContent: "center",
                                  }}
                                >
                                  {quiz.attended > 0 ? (
                                    <CustomButton
                                      disabled
                                      className={
                                        classes.inActiveAccordionButton
                                      }
                                      variant="contained"
                                      color="secondary"
                                      fullWidth
                                      sx={{
                                        maxWidth: { xs: "100%", sm: "200px" },
                                      }}
                                    >
                                      Already Attempted
                                    </CustomButton>
                                  ) : new Date(quiz.start_date) > new Date() ? (
                                    <CustomButton
                                      disabled
                                      className={
                                        classes.inActiveAccordionButton
                                      }
                                      variant="contained"
                                      color="secondary"
                                      fullWidth
                                      sx={{
                                        maxWidth: { xs: "100%", sm: "200px" },
                                      }}
                                    >
                                      Coming Soon
                                    </CustomButton>
                                  ) : (
                                    <CustomButton
                                      endIcon={<ChevronRightIcon />}
                                      href={`/quiz/${
                                        quiz.folder_name
                                      }?${Date.now()}`}
                                      className={
                                        classes.inActiveAccordionButton
                                      }
                                      variant="contained"
                                      color="secondary"
                                      fullWidth
                                      sx={{
                                        maxWidth: { xs: "100%", sm: "200px" },
                                      }}
                                    >
                                      Attempt
                                    </CustomButton>
                                  )}
                                </AccordionActions>
                              </Accordion>
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                    </TabPanel>
                  );
                })}
            </div>
          </SwipeableViews>
        </Box>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent id="shareContent">
          <Typography className={classes.userName}>
            {common?.userDetails?.name}
          </Typography>
          <div>
            <img src={image} alt="img" width={280} />
          </div>
        </DialogContent>
        <DialogActions>
          <IconButton edge="end" color="inherit" onClick={handleShare}>
            <ShareIcon />
          </IconButton>
          <IconButton edge="end" color="inherit" onClick={handleDownload}>
            <CloudDownloadIcon />
          </IconButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
