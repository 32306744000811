import {
  SET_CERTIFICATES,
  SET_BADGES,
  SET_GROUP_INFO,
  SET_GROUP_LIST_FRONT,
  SET_LANGUAGES,
  SET_QUIZ_INFO,
  SET_TIMELINES,
  SET_FRONT_QUIZ_LIST,
  SET_FRONT_PROMOTION_LIST,
  SET_USER_DETAILS,
  SET_VER,
  SET_MARK_LIST,
  SET_TOTAL_MARK,
  SET_GROUP_DATA,
  SET_RANK,
  SET_LEADERBOARD_DATA,
  SET_JAMIAT_LEADERBOARD_DATA,
  SET_JAMAAT_LEADERBOARD_DATA,
} from "../constants/commonConst";

const initial_state = {
  certificates: [],
  badges: [],
  groupInfo: "",
  groupListFront: [],
  languages: "",
  quizInfo: "",
  timelines: "",
  frontQuizList: [],
  frontPromotionList: [],
  userDetails: "",
  userVer: "",
  markList: [],
  totalMark: "",
  groupData: [],
  rank: "",
  leaderboardData: [],
  jamiatLeaderboardData: [],
  jamaatLeaderboardData: [],
};

export default function reducer(state = initial_state, action) {
  switch (action.type) {
    case SET_CERTIFICATES:
      return (state = { ...state, certificates: action.payload });
    case SET_BADGES:
      return (state = { ...state, badges: action.payload });
    case SET_GROUP_INFO:
      return (state = { ...state, groupInfo: action.payload });
    case SET_GROUP_LIST_FRONT:
      return (state = { ...state, groupListFront: action.payload });
    case SET_LANGUAGES:
      return (state = { ...state, languages: action.payload });
    case SET_QUIZ_INFO:
      return (state = { ...state, quizInfo: action.payload });
    case SET_TIMELINES:
      return (state = { ...state, timelines: action.payload });
    case SET_FRONT_QUIZ_LIST:
      return (state = { ...state, frontQuizList: action.payload });
    case SET_FRONT_PROMOTION_LIST:
      return (state = { ...state, frontPromotionList: action.payload });
    case SET_USER_DETAILS:
      return (state = { ...state, userDetails: action.payload });
    case SET_VER:
      return (state = { ...state, userVer: action.payload });
    case SET_MARK_LIST:
      return (state = { ...state, markList: action.payload });
    case SET_TOTAL_MARK:
      return (state = { ...state, totalMark: action.payload });
    case SET_GROUP_DATA:
      return (state = { ...state, groupData: action.payload });
    case SET_RANK:
      return (state = { ...state, rank: action.payload });
    case SET_LEADERBOARD_DATA:
      return (state = { ...state, leaderboardData: action.payload });
    case SET_JAMIAT_LEADERBOARD_DATA:
      return (state = { ...state, jamiatLeaderboardData: action.payload });
    case SET_JAMAAT_LEADERBOARD_DATA:
      return (state = { ...state, jamaatLeaderboardData: action.payload });
    default:
      return state;
  }
}
