import React, { useState, useRef } from "react";
import {
  AppBar,
  DialogContent,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import { Button, Col, Form, Row } from "react-bootstrap";
import { FaTimes } from "react-icons/fa";
import { toast } from "react-toastify";

const base64 = require("base-64");

const pairs = document.cookie.split(";");
let cookies = {};
for (var i = 0; i < pairs.length; i++) {
  var pair = pairs[i].split("=");
  cookies[(pair[0] + "").trim()] = unescape(pair.slice(1).join("="));
}
const authToken = base64.encode(cookies.userid + ":" + cookies.ver);

export const QuizUploadForm = ({ handleCloseUpload, editData }) => {
  const [formData, setFormData] = useState({
    folder_name: editData ? editData.folder_name : "",
  });

  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleUploadClick = (event) => {
    event.preventDefault();
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formDataObject = new FormData();
    formDataObject.append("folder_name", formData.folder_name);
    formDataObject.append("file", selectedFile);
    formDataObject.append("quizData", JSON.stringify(editData));

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}admin/upload_folder`,
        {
          method: "POST",
          headers: {
            Authorization: `Basic ${authToken}`,
          },
          body: formDataObject,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit form data");
      }

      const responseData = await response.json();
      toast.success("File Upload Successfully", responseData);
      handleCloseUpload();
    } catch (error) {
      toast.error("Error Uploading File", error.message);
    }
  };

  return (
    <>
      <AppBar sx={{ position: "static", backgroundColor: "#1D3557" }}>
        <Toolbar>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Add Quiz Folder File
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleCloseUpload}
            aria-label="close"
          >
            <FaTimes />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Form name="QuizEditForm" id="QuizEditForm" onSubmit={handleSubmit}>
          <Row>
            {/* Left Form */}
            <Col xs={12} md={6}>
              <Form.Group controlId="folder_name">
                <Typography variant="h6" component="p">
                  Folder Name: {formData.folder_name}
                </Typography>
              </Form.Group>

              <Form.Group controlId="upload_file">
                <Typography variant="body1" component="p">
                  Folder File:{" "}
                  {selectedFile ? selectedFile.name : "No file selected"}
                </Typography>
                <Button
                  variant="contained"
                  style={{ marginTop: "10px" }}
                  onClick={handleUploadClick}
                >
                  Upload File
                </Button>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
              </Form.Group>

              <Button style={{ marginTop: "20px" }} type="submit">
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </DialogContent>
    </>
  );
};
