import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import DropDown from "./DropDown";
import AdminMenu from "../AdminMenu";
import UserListTable from "./PaperWisePages/UserListTable";
import { TotalUserDetails } from "./PaperWisePages/TotalUserDetails";

const PaperWisePage = () => {
  const { quizId } = useParams();

  const baseUrl = "https://talabulilm.com/imtihaan/imtihaan-api/api/get/";
  const [groupId, setGroupId] = useState(null);
  const [url1Data, setUrl1Data] = useState([]);
  const [url2Data, setUrl2Data] = useState([]);

  const [url4Data, setUrl4Data] = useState([]);

  useEffect(() => {
    const fetchUrlData = async () => {
      if (!quizId) {
        return;
      }

      try {
        const url2 = `${baseUrl}quiz_data/${quizId}`;
        const response2 = await fetch(url2);
        const data2 = await response2.json();
        setUrl2Data(data2.data);
        setGroupId(data2.data.group_id);

        const url1 = `${baseUrl}total_unique_user/${quizId}/${data2.data.group_id}/all`;
        const response1 = await fetch(url1);
        const data1 = await response1.json();
        setUrl1Data(data1);

        const url4 = `${baseUrl}total_completed_unique_user/${quizId}/${data2.data.group_id}/all `;
        const response4 = await fetch(url4);
        const data4 = await response4.json();
        setUrl4Data(data4);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchUrlData();
  }, []);

  useEffect(() => {
    // console.log(url1Data, "url1");
    // console.log(url2Data, "url2");
    // console.log(url4Data, "url4");
    // console.log(url5Data, "url5");
  }, [quizId]);

  return (
    <div style={styles.container}>
      <AdminMenu />
      <div style={styles.content}>
        <h3
          className="admin-table"
          style={{
            marginTop: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {url2Data.report_name}
          {/* <DropDown /> */}
        </h3>
        {groupId && (
          <>
            <TotalUserDetails groupId={groupId} />
            <div style={styles.tableWrapper}>
              <UserListTable groupId={groupId} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
const styles = {
  container: {
    display: "flex",
    flexDirection: "row",
    height: "100vh", // Full viewport height
    overflow: "hidden", // Prevent page overflow
    boxSizing: "border-box",
  },
  content: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    padding: "20px",
    overflowY: "auto", // Allow vertical scrolling within the content area
    boxSizing: "border-box",
  },

  tableWrapper: {
    flex: 1,
    overflowX: "auto", // Allow horizontal scrolling for tables
    maxWidth: "100%",
    boxSizing: "border-box",
  },
  sideMenu: {
    height: "100vh",
  },
};

export default PaperWisePage;
