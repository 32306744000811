import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getLeaderboard,
  getJamiatLeaderboard,
  getJamaatLeaderboard,
} from "../../actions/marksheetAction/marksheetAction";
import LeaderBoard from "../../components/leaderBoard/leaderBoard";

export class LeaderBoardCont extends Component {
  render() {
    return <LeaderBoard {...this.props} />;
  }
}
export const mapStateToProps = (store) => {
  return {
    common: store.common,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    getLeaderboard: (user_its, group_id) => {
      dispatch(getLeaderboard(user_its, group_id));
    },
    //new
    getJamiatLeaderboard: (group_id, jamiat_id) => {
      dispatch(getJamiatLeaderboard(group_id, jamiat_id));
    },
    getJamaatLeaderboard: (group_id, jamaat_id) => {
      dispatch(getJamaatLeaderboard(group_id, jamaat_id));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(LeaderBoardCont);
