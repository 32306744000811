import React, { useState } from "react";
import {
  AppBar,
  DialogContent,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import { Button, Col, Form, Row } from "react-bootstrap";
import { FaTimes } from "react-icons/fa";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { toast } from "react-toastify";

const base64 = require("base-64");
const pairs = document.cookie.split(";");
let cookies = {};
for (var i = 0; i < pairs.length; i++) {
  var pair = pairs[i].split("=");
  cookies[(pair[0] + "").trim()] = unescape(pair.slice(1).join("="));
}
const authToken = base64.encode(cookies.userid + ":" + cookies.ver);

const GroupAddForm = ({ handleClose, data }) => {
  const [editorData, setEditorData] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    time_limit: "",
    is_active: "0",
    start_date: "",
    end_date: "",
    user_its: "",
  });

  const handleChange = (event, editor) => {
    const data = editor.getData();
    setEditorData(data);
  };
  //--------------------------------------------------------
  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}admin/group_data`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Basic ${authToken}`,
          },
          body: JSON.stringify({
            ...formData,
            description: editorData,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit form data");
      }

      const responseData = await response.json();
      // console.log("Group Added successfully", responseData);
      toast.success("Group Added successfully");

      // Optionally, you can reset the form fields and editor content after submission
      setFormData({
        name: "",
        time_limit: "",
        is_active: "0",
        start_date: "",
        end_date: "",
        user_its: "",
      });
      setEditorData("");
    } catch (error) {
      console.error("Error Adding Group", error.message);
      toast.error("Error Adding Group", error.message);
    }
  };

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    const inputValue = type === "checkbox" ? checked : value;

    setFormData({
      ...formData,
      [name]: inputValue,
    });
  };
  // console.log(user_its, "us");
  //-----------------------------------------------------

  return (
    <>
      <AppBar sx={{ position: "static", backgroundColor: "#1D3557" }}>
        <Toolbar>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Add Group Info
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <FaTimes />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Form onSubmit={handleSubmit} name="Group Add Form" id="Group AddForm">
          <Row>
            {/* Left Form */}
            <Col xs={12} md={5}>
              <Form.Group className="mb-2">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "15px",
                  }}
                >
                  <Form.Control
                    name="user_its"
                    type="text"
                    placeholder="Enter User_ITS"
                    value={formData.user_its}
                    onChange={handleInputChange}
                  />

                  <Form.Control
                    name="name"
                    type="text"
                    placeholder="Enter Name"
                    value={formData.name}
                    onChange={handleInputChange}
                  />
                  <Form.Control
                    name="time_limit"
                    type="number"
                    placeholder="Enter Time limit"
                    value={formData.time_limit}
                    onChange={handleInputChange}
                  />
                  <Form.Group controlId="is_active">
                    <Form.Check
                      name="is_active"
                      type="checkbox"
                      label="Is Active"
                      checked={formData.is_active}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </div>
              </Form.Group>
              <Form.Text>
                <span style={{ color: "red" }}>Note</span>: End Date must be
                Higher Than the Start Date
              </Form.Text>
              <Row>
                <Col>
                  <Form.Group controlId="startDate">
                    <Form.Label>Start Date</Form.Label>
                    <Form.Control
                      type="date"
                      name="start_date"
                      value={formData.start_date}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                  <Button style={{ marginTop: "20px" }} type="submit">
                    Submit
                  </Button>
                </Col>
                <Col>
                  <Form.Group controlId="endDate">
                    <Form.Label>End Date</Form.Label>
                    <Form.Control
                      type="date"
                      name="end_date"
                      value={formData.end_date}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Col>

            {/* Right Form */}
            <Col xs={12} md={7}>
              <Form.Label>Description</Form.Label>
              <CKEditor
                name="description"
                editor={ClassicEditor}
                data={editorData}
                onChange={handleChange}
              />
            </Col>
          </Row>
        </Form>
      </DialogContent>
    </>
  );
};

export default GroupAddForm;
