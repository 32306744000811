import React, { useEffect, useState } from "react";
import {
  Table,
  TableContainer,
  TableCell,
  TableBody,
  TableHead,
  Box,
  Paper,
  TableRow,
  Typography,
  Tabs,
  Tab,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:hover": {
      backgroundColor: "#23C4ED33",
    },
  },
}))(TableRow);

export default function LeaderBoard(props) {
  const { common, getLeaderboard, getJamiatLeaderboard, getJamaatLeaderboard } =
    props;
  const [activeTab, setActiveTab] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    getLeaderboard(common.userDetails.its_id, props.match.params.id);
    getJamiatLeaderboard(props.match.params.id, common.userDetails.jamiat);
    getJamaatLeaderboard(props.match.params.id, common.userDetails.jamaat);
  }, [common.userDetails]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const renderTable = (data) => (
    <TableContainer
      component={Paper}
      style={{
        width: "100%",
        overflow: "hidden", // Prevent container overflow
        display: "flex",
        flexDirection: "column",
        maxHeight: isMobile ? "calc(100vh - 150px)" : "none", // Allow the table to fit within the screen height, accounting for header
      }}
    >
      {/* Fixed Header */}
      <Box
        style={{
          borderBottom: "1px solid gray",
          width: "100%",
          flex: "none", // Prevent flex stretching
        }}
      >
        <Typography
          style={{
            textTransform: "uppercase",
            fontWeight: "bold",
            padding: "20px",
            backgroundColor: "#457B9D",
            color: "#fff",
            width: "100%",
          }}
        >
          {props.match.params.slug}
        </Typography>
      </Box>

      <h5
        style={{
          padding: "0.6rem 0.8rem",
          fontWeight: 600,
          letterSpacing: 0.6,
          background: "#ffffff",
          fontSize: "1rem",
        }}
      >
        Note:{" "}
        <span style={{ fontSize: "0.8rem", fontWeight: 400 }}>
          This leaderboard is updated every hour. If you've submitted a paper
          and your score isn't showing yet, don't worry! It will appear on the
          leaderboard during the next hourly update.
        </span>
      </h5>

      {/* Fixed Tabs */}
      <Box style={{ width: "100%", flex: "none" }}>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          TabIndicatorProps={{
            style: { backgroundColor: "#457B9D", height: "3px" },
          }}
          textColor="primary"
          style={{ color: "#457B9D" }}
        >
          <Tab label="WorldWide" style={{ color: "#457B9D" }} />
          <Tab label="Jamiat" style={{ color: "#457B9D" }} />
          <Tab label="Jamaat" style={{ color: "#457B9D" }} />
        </Tabs>
      </Box>

      {/* Scrollable Table Content */}
      <Box
        style={{
          width: "100%",
          height: "100%", // Ensures container takes available height
          overflowX: "auto", // Allow horizontal scrolling
          overflowY: "auto", // Allow vertical scrolling
          "&::-webkit-scrollbar": {
            height: "8px",
          },
          "&::-webkit-scrollbar-track": {
            background: "#f1f1f1",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#888",
            borderRadius: "4px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            background: "#555",
          },
        }}
      >
        {data?.length > 0 ? (
          <Table
            stickyHeader
            aria-label="a dense table"
            style={{
              minWidth: "100%",
              width: "max-content", // Allow table to expand based on content
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: "bold", whiteSpace: "nowrap" }}>
                  Rank
                </TableCell>
                <TableCell style={{ fontWeight: "bold", whiteSpace: "nowrap" }}>
                  User Name
                </TableCell>
                <TableCell style={{ fontWeight: "bold", whiteSpace: "nowrap" }}>
                  Jamat
                </TableCell>
                <TableCell style={{ fontWeight: "bold", whiteSpace: "nowrap" }}>
                  Jamiat
                </TableCell>
                <TableCell style={{ fontWeight: "bold", whiteSpace: "nowrap" }}>
                  Age
                </TableCell>
                <TableCell style={{ fontWeight: "bold", whiteSpace: "nowrap" }}>
                  Score
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((user, index) => {
                const isLoggedInUser =
                  user.its_id === common.userDetails.its_id;
                return (
                  <StyledTableRow
                    key={user.id}
                    style={{
                      ...(!isLoggedInUser && {
                        backgroundColor:
                          index % 2 === 0 ? "#ffffff" : "#f9f9f9",
                        transition: "background-color 0.2s ease-in-out",
                      }),
                      ...(isLoggedInUser && {
                        backgroundColor: "#e0f7fa",
                        fontWeight: "bold",
                        transition: "background-color 0.2s ease-in-out",
                      }),
                      cursor: "pointer",
                    }}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.backgroundColor = isLoggedInUser
                        ? "#b2ebf2"
                        : "#f2f2f2";
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.backgroundColor = isLoggedInUser
                        ? "#e0f7fa"
                        : index % 2 === 0
                        ? "#ffffff"
                        : "#f9f9f9";
                    }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      style={{
                        color: user.rank < 1 ? "#bda420" : "inherit",
                        fontWeight: user.rank < 1 ? "bold" : "inherit",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {user.rank < 1 ? "GOLD LEAGUE" : user.rank}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      style={{
                        fontWeight: isLoggedInUser ? "bold" : "normal",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {user.name}
                    </TableCell>
                    <TableCell style={{ whiteSpace: "nowrap" }}>
                      {user.jamaat}
                    </TableCell>
                    <TableCell style={{ whiteSpace: "nowrap" }}>
                      {user.jamiat}
                    </TableCell>
                    <TableCell style={{ whiteSpace: "nowrap" }}>
                      {user.age}
                    </TableCell>
                    <TableCell style={{ whiteSpace: "nowrap" }}>
                      {user.score}
                    </TableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        ) : (
          <h5
            style={{
              textAlign: "center",
              width: "100%",
              margin: "2rem auto",
              fontWeight: 500,
              letterSpacing: 0.6,
            }}
          >
            No data available
          </h5>
        )}
      </Box>
    </TableContainer>
  );

  return (
    <>
      <Box m={3}>
        <Box mt={2}>
          {activeTab === 0 && renderTable(common.leaderboardData)}
          {activeTab === 1 && renderTable(common.jamiatLeaderboardData || [])}
          {activeTab === 2 && renderTable(common.jamaatLeaderboardData || [])}
        </Box>
      </Box>
    </>
  );
}
