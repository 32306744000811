import React, { useState } from "react";
import { FaDownload } from "react-icons/fa";
import { useGlobalFilter, usePagination, useTable } from "react-table";
import { ThreeDots } from "react-loader-spinner";
const TableComponent = ({ columns, data }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageIndex, pageSize, globalFilter },
    setGlobalFilter,
    setPageSize,
    gotoPage,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useGlobalFilter,
    usePagination
  );

  // ------------------Dowload Csv File--------------------------------------

  const escapeCSVValue = (value) => {
    if (value === null || value === undefined) return "";
    const stringValue = String(value);
    if (
      stringValue.includes(",") ||
      stringValue.includes('"') ||
      stringValue.includes("\n")
    ) {
      return `"${stringValue.replace(/"/g, '""')}"`;
    }
    return stringValue;
  };

  const formatDateWithSeconds = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return dateString;

    return date.toLocaleString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    });
  };

  const downloadCsv = () => {
    // Define CSV headers
    const headers = [
      "No.",
      "ITS ID",
      "Name",
      "Mobile No",
      "Email",
      "DOB",
      "Gender",
      "Age",
      "Jammat",
      "Jamiat",
      "Tanzeem ID",
      "Marhala",
      "totalScore",
      "City",
      "Country",
      "organization",
      "submitted_date",
    ];

    const csvContent = [
      headers.join(","), // Add headers
      ...data.map((row, index) => {
        const selectedValues = [
          index + 1, // Auto-increment number
          row.its_id,
          row.name,
          row.phone,
          row.email,
          row.dob,
          row.gender,
          row.age,
          row.jamaat,
          row.jamiat,
          row.tanzeem_id,
          row.marhala,
          row.totalScore,
          row.city,
          row.country,
          row.organization,
          formatDateWithSeconds(row.submitted_date),
        ];
        return selectedValues.map((value) => escapeCSVValue(value)).join(",");
      }),
    ].join("\n");

    // Create and trigger download
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "user_list.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  // ------------------Page Index--------------------------------------
  const [customPageIndex, setCustomPageIndex] = useState(pageIndex + 1);

  const handleCustomPageIndexChange = (e) => {
    const enteredPageIndex = parseInt(e.target.value, 10);
    setCustomPageIndex(isNaN(enteredPageIndex) ? 1 : enteredPageIndex);
  };

  const handleCustomPageIndexKeyPress = (e) => {
    if (e.key === "Enter") {
      const validPageIndex = Math.max(
        1,
        Math.min(customPageIndex, page ? Math.ceil(data.length / pageSize) : 1)
      );
      gotoPage(validPageIndex - 1);
    }
  };
  // ------------------Page Size--------------------------------------
  const [pageSizeOption, setPageSizeOption] = useState(10);

  const handlePageSizeOptionChange = (e) => {
    const selectedOption = parseInt(e.target.value, 10);
    setPageSizeOption(selectedOption);
    setPageSize(selectedOption);
    gotoPage(0);
  };
  // --------------------------------------------------------------
  const [filters, setFilters] = useState({});

  const handleFilterChange = (columnId, value) => {
    setFilters((prevFilters) => ({ ...prevFilters, [columnId]: value }));
    setGlobalFilter(value);
  };

  return (
    <>
      {data.length === 0 ? (
        <span
          style={{
            display: "block",
            width: "50px",
            margin: "auto",
            position: "inline",
            textAlign: "center",
          }}
        >
          <ThreeDots color="darkblue" size={50} />
        </span>
      ) : (
        <>
          <a onClick={downloadCsv}>
            <p style={{ fontSize: "0.9em", cursor: "pointer" }}>
              <FaDownload size={15} color="green" /> Download Csv
            </p>
          </a>

          <hr className="underline"></hr>

          <table
            {...getTableProps()}
            style={{ width: "100%", borderCollapse: "collapse" }}
            key={data.id}
          >
            <thead>
              {headerGroups.map((headerGroup, index) => (
                <tr
                  {...headerGroup.getHeaderGroupProps()}
                  key={`header-group-${index}`}
                >
                  {headerGroup.headers.map((column, columnIndex) => (
                    <th
                      {...column.getHeaderProps()}
                      key={`column-${columnIndex}`}
                      style={{ backgroundColor: "white" }}
                    >
                      {column.id === "indexColumnId"
                        ? "No."
                        : column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
              <tr>
                {headerGroups.map((headerGroup, headerGroupIndex) => (
                  <React.Fragment
                    key={`header-group-fragment-${headerGroupIndex}`}
                  >
                    {headerGroup.headers.map((column, columnIndex) => (
                      <td key={`header-${headerGroupIndex}-${columnIndex}`}>
                        {column.id !== "indexColumnId" && (
                          <div>
                            <input
                              type="text"
                              className="table-input"
                              value={filters[column.id] || ""}
                              onChange={(e) =>
                                handleFilterChange(column.id, e.target.value)
                              }
                            />
                          </div>
                        )}
                      </td>
                    ))}
                  </React.Fragment>
                ))}
              </tr>
            </thead>
            <tbody {...getTableBodyProps()} key={data.id}>
              {page
                ? page.map((row, rowIndex) => {
                    prepareRow(row);
                    return (
                      <tr
                        {...row.getRowProps()}
                        key={`row-${rowIndex}-${data.id}`}
                      >
                        {row.cells.map((cell, cellIndex) => (
                          <td
                            {...cell.getCellProps()}
                            key={`cell-${cellIndex}-${data.id}`}
                          >
                            {cell.render("Cell")}
                          </td>
                        ))}
                      </tr>
                    );
                  })
                : null}
            </tbody>
          </table>
        </>
      )}
      {/* -----------------Pagination------------------------------ */}
      <div>
        <div className="pagination">
          <button
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            className="table-btn"
          >
            {"Previous"}
          </button>

          <span className="table-pageSet">
            Page{" "}
            <input
              type="number"
              value={customPageIndex}
              onKeyDown={handleCustomPageIndexKeyPress}
              onChange={handleCustomPageIndexChange}
              min="1"
              style={{ padding: "10px", margin: "10px", borderRadius: "5px" }}
              max={page ? Math.ceil(data.length / pageSize) : 1}
            />{" "}
            of {page ? Math.ceil(data.length / pageSize) : 0}
            <label className="table-pageSet">
              <select
                value={pageSizeOption}
                onChange={handlePageSizeOptionChange}
                style={{ padding: "10px", margin: "10px", borderRadius: "5px" }}
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
              </select>
            </label>
          </span>
          <button
            onClick={() => nextPage()}
            disabled={!canNextPage}
            className="table-btn"
          >
            {"Next"}
          </button>
        </div>
      </div>
    </>
  );
};

export default TableComponent;
