import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  AppBar,
  DialogContent,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { FaTimes } from "react-icons/fa";
import DropdownOptions from "../DashBoard/DropDown";

import { toast } from "react-toastify";

const base64 = require("base-64");

const pairs = document.cookie.split(";");
let cookies = {};
for (var i = 0; i < pairs.length; i++) {
  var pair = pairs[i].split("=");
  cookies[(pair[0] + "").trim()] = unescape(pair.slice(1).join("="));
}
const authToken = base64.encode(cookies.userid + ":" + cookies.ver);

const QuizEditForm = ({ handleCloseEdit, selectRowId, editData }) => {
  const [editorData, setEditorData] = useState(
    editData ? editData.description : ""
  );
  // const [isActive, setIsAcive] = useState(false);

  const [formData, setFormData] = useState({
    group_name: editData ? editData.group_name : "",
    group_id: editData ? editData.group_id.value : "",
    language: editData ? editData.language : "",
    report_name: editData ? editData.report_name : "",
    folder_name: editData ? editData.folder_name : "",
    name: editData ? editData.name : "",
    max_score: editData ? editData.max_score : "",
    time_limit: editData ? editData.time_limit : "",
    slide_count: editData ? editData.slide_count : "",
    total_iteractions: editData ? editData.total_iteractions : "",
    pass_value: editData ? editData.pass_value : "",
    start_date: editData ? editData.start_date : "",
    end_date: editData ? editData.end_date : "",
    is_active: editData ? editData.is_active : "0",
    id: selectRowId,
  });

  const handleChange = (event, editor) => {
    const data = editor.getData();
    setEditorData(data);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}admin/quiz_data`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Basic ${authToken}`,
          },
          body: JSON.stringify({
            ...formData,
            description: editorData,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit form data");
      }

      const responseData = await response.json();

      toast.success(" Edit Data successfully", responseData);
      // console.log("Form data submitted successfully", responseData);
      // console.log(formData, "fdata");

      setEditorData("");
    } catch (error) {
      // console.error("Error submitting form data", error.message);
      toast.error("Error submitting form data", error.message);
      // console.log(formData, "fdata");
    }
  };

  const [editedData, setEditedData] = useState([]);

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    const inputValue = type === "checkbox" ? (checked ? "1" : "0") : value;

    setFormData({
      ...formData,
      [name]: value,
    });
    // setEditedData({
    //   ...editedData,
    //   [name]: value,
    // });
  };

  const handleSelectChange = (value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      group_id: value.target.value,
    }));
  };

  return (
    <>
      <AppBar sx={{ position: "static", backgroundColor: "#1D3557" }}>
        <Toolbar>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Edit Quiz Info
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleCloseEdit}
            aria-label="close"
          >
            <FaTimes />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Form onSubmit={handleSubmit} name="QuizEditForm" id="QuizEditForm">
          <Row>
            {/* Left Form */}
            <Col xs={12} md={6}>
              <Form.Group className="mb-2">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "15px",
                  }}
                >
                  <Form.Group controlId="group_Id">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Form.Label style={{ marginRight: "25em" }}>
                        Groups
                      </Form.Label>
                      <span style={{ marginRight: "46em" }}>
                        <DropdownOptions
                          handleSelectChange={handleSelectChange}
                          name="group_id"
                        />
                      </span>
                    </div>
                  </Form.Group>

                  <Form.Group controlId="language">
                    <Form.Label>Language</Form.Label>
                    <Form.Control
                      as="select"
                      name="language"
                      value={formData.language}
                      onChange={handleInputChange}
                    >
                      <option value={""} disabled>
                        Choose
                      </option>
                      <option value={"lsd"}>LSD</option>
                      <option value={"eng"}>ENG</option>
                      <option value={"guj"}>GUJ</option>
                      <option value={"frn"}>FRN</option>
                      <option value={"ind"}>IND</option>
                      <option value={"arb"}>ARB</option>
                    </Form.Control>
                  </Form.Group>

                  <Form.Group controlId="report_name">
                    <Form.Label>Report Name</Form.Label>
                    <Form.Control
                      name="report_name"
                      type="text"
                      value={formData.report_name}
                      onChange={handleInputChange}
                    />
                  </Form.Group>

                  <Form.Group controlId="folder_name">
                    <Form.Label>Folder Name</Form.Label>
                    <Form.Control
                      name="folder_name"
                      type="text"
                      value={formData.folder_name}
                      onChange={handleInputChange}
                    />
                  </Form.Group>

                  <Form.Group controlId="name">
                    <Form.Label> Name</Form.Label>
                    <Form.Control
                      name="name"
                      type="text"
                      value={formData.name}
                      onChange={handleInputChange}
                    />
                  </Form.Group>

                  <Form.Group controlId="max_score">
                    <Form.Label>Max Score</Form.Label>
                    <Form.Control
                      name="max_score"
                      type="text"
                      value={formData.max_score}
                      onChange={handleInputChange}
                    />
                  </Form.Group>

                  <Form.Group controlId="time_limit">
                    <Form.Label>Time Limit</Form.Label>
                    <Form.Control
                      name="time_limit"
                      type="number"
                      value={formData.time_limit}
                      onChange={handleInputChange}
                    />
                  </Form.Group>

                  <Form.Group controlId="slide_count">
                    <Form.Label>Slide Count</Form.Label>
                    <Form.Control
                      name="slide_count"
                      type="text"
                      value={formData.slide_count}
                      onChange={handleInputChange}
                    />
                  </Form.Group>

                  <Form.Group controlId="total_iteractions">
                    <Form.Label>Total Iteractions</Form.Label>
                    <Form.Control
                      name="total_iteractions"
                      type="number"
                      value={formData.total_iteractions}
                      onChange={handleInputChange}
                    />
                  </Form.Group>

                  <Form.Group controlId="pass_value">
                    <Form.Label>Pass Value</Form.Label>
                    <Form.Control
                      name="pass_value"
                      type="text"
                      value={formData.pass_value}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </div>
              </Form.Group>
              <Form.Text>
                <span style={{ color: "red" }}>Note</span>: End Date must be
                Higher Than the Start Date
              </Form.Text>
              <Row>
                <Col>
                  <Form.Group controlId="start_date">
                    <Form.Label>Start Date</Form.Label>
                    <Form.Control
                      type="date"
                      name="start_date"
                      value={formData.start_date}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                  <Form.Group controlId="is_active">
                    <Form.Check
                      name="is_active"
                      type="checkbox"
                      label="Is Active"
                      checked={formData.is_active}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                  <Button style={{ marginTop: "20px" }} type="submit">
                    Submit
                  </Button>
                </Col>
                <Col>
                  <Form.Group controlId="end_date">
                    <Form.Label>End Date</Form.Label>
                    <Form.Control
                      type="date"
                      name="end_date"
                      value={formData.end_date}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Col>

            {/* Right Form */}
            <Col xs={12} md={6}>
              <Form.Group controlId="description">
                <Form.Label>Description</Form.Label>
                <CKEditor
                  name="description"
                  editor={ClassicEditor}
                  data={editorData}
                  value={formData.description}
                  onChange={handleChange}
                  className="ck-editor__editable"
                />
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </DialogContent>
    </>
  );
};

export default QuizEditForm;
